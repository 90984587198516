import { IonItem, IonLabel, IonList } from '@ionic/react';
import { memo, useMemo } from 'react';
import { useHistory } from 'react-router';
import { IGlobalIndexDict } from '../../models/IGlobalIndexItem';
import { DiscoverListStyled } from './DiscoverList.styled';

export interface IDiscoverListProps {
  globalIndexDict: IGlobalIndexDict | null;
}
const DEFAULT_LOGO_URL = '/assets/icon/library.png';
const DiscoverListComponent = ({ globalIndexDict }: IDiscoverListProps) => {
  const history = useHistory();
  const itemComponents = useMemo(
    () =>
      globalIndexDict?.libaries.map((libary) => (
        <IonItem
          key={libary.name}
          button
          onClick={() => {
            history.push(`/discover/${libary.name}`);
          }}
        >
          <DiscoverListStyled.ListItemImageWrapper slot="start">
            <DiscoverListStyled.ListItemImage alt={libary.name} src={libary.logoUrl || DEFAULT_LOGO_URL} />
          </DiscoverListStyled.ListItemImageWrapper>
          <IonLabel className="ion-text-wrap">
            <h2>{libary.name}</h2>
            <p>
              <span>Total Books: {libary.totalBooks}</span>
              {!!libary.languages?.length && (
                <>
                  <br />
                  <span>
                    Languages:{' '}
                    {libary.languages.length <= 3
                      ? libary.languages.join(', ')
                      : //get first 3 languages
                        libary.languages.slice(0, 3).join(', ') + ` and ${libary.languages.length - 3} more`}
                  </span>
                </>
              )}
            </p>
          </IonLabel>
        </IonItem>
      )),
    [globalIndexDict?.libaries, history]
  );

  return <IonList>{itemComponents}</IonList>;
};

export const DiscoverList = memo(DiscoverListComponent);
