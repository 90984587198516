import { IBook } from '../../models/IBookDict';
import { shufferArray } from '../../utils/array.helper';

function fillOldPaper(canvas: HTMLCanvasElement): void {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }

  // Create the pattern for the paper
  const patternCanvas = document.createElement('canvas');
  patternCanvas.width = 30;
  patternCanvas.height = 30;
  const patternCtx = patternCanvas.getContext('2d');
  if (!patternCtx) {
    return;
  }

  patternCtx.fillStyle = '#f6eedf';
  patternCtx.fillRect(0, 0, 30, 30);

  patternCtx.strokeStyle = '#c4aa7e';
  patternCtx.lineWidth = 2;
  patternCtx.beginPath();
  for (let i = 0; i < 30; i += 3) {
    patternCtx.moveTo(i, 0);
    patternCtx.lineTo(i, 30);
    patternCtx.stroke();
  }

  patternCtx.beginPath();
  for (let i = 0; i < 30; i += 3) {
    patternCtx.moveTo(0, i);
    patternCtx.lineTo(30, i);
    patternCtx.stroke();
  }

  patternCtx.fillStyle = '#d9c2a2';
  patternCtx.beginPath();
  for (let i = 0; i < 50; i++) {
    const x = Math.random() * patternCanvas.width;
    const y = Math.random() * patternCanvas.height;
    const radius = Math.random() * 2 + 1;
    patternCtx.arc(x, y, radius, 0, Math.PI * 2);
    patternCtx.fill();
  }

  // Fill the canvas with the pattern
  const pattern = ctx.createPattern(patternCanvas, 'repeat');
  ctx.fillStyle = pattern || '#f9d3a3';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
}

function wrapText(text: string, maxWidth: number, context: CanvasRenderingContext2D, fontSize: number): string[] {
  const words = text.split(' ');
  const lines = [];
  let line = '';

  for (let i = 0; i < words.length; i++) {
    const testLine = line + words[i] + ' ';
    const metrics = context.measureText(testLine);
    const testWidth = metrics.width;
    if (testWidth > maxWidth && i > 0) {
      lines.push(line);
      line = words[i] + ' ';
    } else {
      line = testLine;
    }
  }
  lines.push(line);

  return lines;
}

export function generateBookCoverImage(
  title: string,
  author: string,
  subtitle: string,
  width = 200,
  height = 300
): string {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return '';
  }
  const fontSize = Math.floor(width / 10);
  const authorFontSize = Math.floor(fontSize * 0.7);
  const subtitleFontSize = Math.floor(fontSize * 0.6);
  const titleY = Math.floor((height * 2) / 5);
  const authorY = Math.floor(height / 4);
  const invertedColor = '#705A3C';

  // Fill pattern to canvas
  fillOldPaper(canvas);

  // Wrap title text
  ctx.fillStyle = invertedColor;
  ctx.font = `bold ${fontSize}px sans-serif`;
  ctx.textAlign = 'center';
  const titleLines = wrapText(title, width, ctx, fontSize);

  // Draw title text
  const titleLineHeight = fontSize * 1.2;
  titleLines.forEach((line, i) => {
    const y = titleY + i * titleLineHeight;
    ctx.fillText(line, Math.floor(width / 2), y);
  });

  // Wrap subtitle text
  if (subtitle) {
    ctx.font = `${subtitleFontSize}px italic sans-serif`;
    const subtitleLines = wrapText(subtitle, width, ctx, subtitleFontSize);

    // Draw subtitle text
    const subtitleLineHeight = subtitleFontSize * 1.2;
    subtitleLines.forEach((line, i) => {
      const y = titleY + titleLines.length * titleLineHeight + i * subtitleLineHeight;
      ctx.fillText(line, Math.floor(width / 2), y);
    });
  }
  // Wrap author text
  ctx.font = `${authorFontSize}px italic sans-serif`;
  const authorLines = wrapText(author, width, ctx, authorFontSize);

  // Draw author text
  const authorLiineHeight = authorFontSize * 1.2;
  authorLines.forEach((line, i) => {
    const y = authorY + i * authorLiineHeight;
    ctx.fillText(line, Math.floor(width / 2), y);
  });

  return canvas.toDataURL();
}

export const removePronouns = (text: string): string => {
  // remove pronouns and Demonstrative pronouns from text
  return text.replace(
    /(he|him|his|himself|she|her|hers|herself|it|its|itself|they|them|their|theirs|themselves|this|that|these|those)/gi,
    ''
  );
};

export const filterBookItems = (items: IBook[], filter?: string, enableShuffer?: boolean): IBook[] => {
  if (!filter) {
    return enableShuffer ? shufferArray(items) : items;
  }
  const filterLower = removePronouns(filter.toLowerCase().trim());
  if (!filterLower) {
    return enableShuffer ? shufferArray(items) : items;
  }
  const filterWords = filterLower.split(' ').reduce((acc, word) => {
    const wordTrim = word.trim();
    if (wordTrim) {
      acc.push(word);
    }
    return acc;
  }, [] as string[]);

  // filter items
  const filteredItems = items.filter((item) => {
    // filter is matched if title, author, subtitle contain any word in filter
    const stringToSearch = ` ${item.title} ${item.author} ${item.subtitle} `.toLocaleLowerCase();
    return filterWords.every((word) => stringToSearch.includes(word));
  });
  // sort items by title
  return filteredItems.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });
};
