import styled from 'styled-components';

interface ILoadedState {
  isLoaded?: boolean;
}
export const EpubReaderWrapper = styled.div<ILoadedState>`
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  width: 100%;
  height: 100%;
`;

export const FooterContainer = styled.div<ILoadedState>`
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  opacity: 0.5;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
`;

export const EpubViewerV2Styled = {
  EpubReaderWrapper,
  FooterContainer,
};
