import { IFileMetaData } from '../models/IFileMetaData';
import { readEpubFileMetadata } from './epub.helper';
import { readPdfFileMetadata } from './pdf.helper';

export const urlToBlob = async (url: string): Promise<Blob> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return blob;
};

export const blobToUrl = (blob: Blob): string => {
  return URL.createObjectURL(blob);
};

export const fileToArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();

    reader.onerror = function onerror(ev) {
      reject(ev.target?.error);
    };

    reader.onload = function onload(ev) {
      resolve(ev.target?.result as ArrayBuffer);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const readFileMetaData = async (
  blob: Blob,
  type?: string,
  fileName?: string
): Promise<IFileMetaData | undefined> => {
  const fileArrayBuffer = await blob.arrayBuffer();
  if (type === 'application/pdf') {
    return await readPdfFileMetadata(fileArrayBuffer, fileName);
  }
  if (type == 'application/epub+zip') {
    return await readEpubFileMetadata(fileArrayBuffer);
  }
};
