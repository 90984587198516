import { IonIcon, IonLabel } from '@ionic/react';
import { book } from 'ionicons/icons';
import { memo, useCallback, useEffect, useState } from 'react';
import { IWithAppStorageProps, withAppStorage } from '../../hocs/withAppStorage';
import { useAppStateService } from '../../hooks/useAppStateService';
import { useBookshelfService } from '../../hooks/useBookshelfService';
import { IBookshelfItem } from '../../models/IBookshelfItem';

export interface IAppBarViewerButtonProps extends IWithAppStorageProps {
  onLastedBookChange?: (lastedBook: IBookshelfItem | undefined) => void;
}

const AppBarViewerButtonComponent = ({ appStorage, onLastedBookChange }: IAppBarViewerButtonProps) => {
  const { getBookshelfItem: getLibraryItem } = useBookshelfService(appStorage);
  const { lastedBook: lastedBookFromStorage } = useAppStateService(appStorage);

  const [lastedBook, setLastedBook] = useState<IBookshelfItem | undefined>(undefined);
  const loadLastedBook = useCallback(async () => {
    const lastedBookItem = lastedBookFromStorage;

    if (lastedBookItem) {
      const item = await getLibraryItem(lastedBookItem.id);
      setLastedBook(item);
      onLastedBookChange?.(item);
    }
    return;
  }, [lastedBookFromStorage, getLibraryItem, onLastedBookChange]);

  useEffect(() => {
    loadLastedBook();
  }, [loadLastedBook]);

  // const coverUrl = useMemo(() => {
  //   const metadata = lastedBook?.fileMetaData;
  //   if (metadata?.cover) {
  //     return metadata.cover;
  //   }
  //   if (metadata?.coverBlob) {
  //     return blobToUrl(metadata.coverBlob);
  //   }
  //   return;
  // }, [lastedBook]);

  // if (!coverUrl) {
  //   return (
  //     <>
  //       <IonIcon icon={book} />
  //       <IonLabel>Viewer</IonLabel>
  //     </>
  //   );
  // }
  return (
    <>
      <IonIcon icon={book} />
      <IonLabel>{lastedBook?.fileMetaData?.title ?? 'Reading'}</IonLabel>
    </>
  );
};

export const AppBarViewerButton = memo(withAppStorage(AppBarViewerButtonComponent));
