import * as pdfjs from 'pdfjs-dist';
import { IFileMetaData } from '../models/IFileMetaData';

// pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.5.141/build/pdf.worker.min.js';
pdfjs.GlobalWorkerOptions.workerSrc = `/assets/js/pdf.worker.min.js`;

export const loadPdf = async (blob: ArrayBuffer) => {
  const pdf = await pdfjs.getDocument(blob).promise;
  return pdf;
};

export const getPdfPageInCanvas = async (
  pdf: pdfjs.PDFDocumentProxy,
  pageNumber: number,
  opts: {
    scale?: number;
  }
) => {
  const { scale = 1 } = opts;
  const page = await pdf.getPage(pageNumber);
  const viewport = page.getViewport({ scale });

  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');
  canvas.width = Math.floor(viewport.width * scale);
  canvas.height = Math.floor(viewport.height * scale);

  const transform = scale !== 1 ? [scale, 0, 0, scale, 0, 0] : undefined;

  if (!canvasContext) {
    return;
  }
  const renderTask = page.render({
    canvasContext,
    viewport,
    transform,
  });
  await renderTask.promise;
  return canvas;
};

export const getPdfBlobCoverDataUrl = async (blob: Blob | ArrayBuffer) => {
  // if blob is not arrayBuffer, convert it to arrayBuffer
  const arrayBuffer = blob instanceof ArrayBuffer ? blob : await blob.arrayBuffer();

  // arrayBuffer to pdf
  const pdf = await loadPdf(arrayBuffer);
  // pdf to canvas
  const canvas = await getPdfPageInCanvas(pdf, 1, { scale: 1 });
  // canvas to dataUrl
  const dataUrl = canvas?.toDataURL();
  return dataUrl;
};

export const readPdfFileMetadata = async (
  fileArrayBuffer: ArrayBuffer | undefined,
  fileName?: string
): Promise<IFileMetaData | undefined> => {
  if (!fileArrayBuffer) {
    return;
  }
  console.log('readPdfFileMetadata', fileArrayBuffer);
  const coverDataUrl = await getPdfBlobCoverDataUrl(fileArrayBuffer);

  return {
    title: fileName,
    cover: coverDataUrl,
  };
};
