import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { memo, useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { useHistory, useLocation } from 'react-router';
import { BookList } from '../../components/book-list/BookList';
import { IBook, IBookDict } from '../../models/IBookDict';
import { IBookshelfItem } from '../../models/IBookshelfItem';
import { LibraryViewerBookDetail } from './LibraryViewerBookDetail';
import { filterBookItems } from './LibraryViewerBookList.helpers';

export interface ILibraryViewerBookListProps {
  bookDict: IBookDict;
  searchString?: string;
}
const LibraryViewerBookListComponent = ({ bookDict, searchString }: ILibraryViewerBookListProps) => {
  const history = useHistory();
  const [, startTransition] = useTransition();
  const [bookItems] = useMemo(() => {
    const books = bookDict?.books || {};
    const items = Object.values(books);
    return [items, items.length];
  }, [bookDict?.books]);

  const [filteredItems] = useMemo(() => {
    const items = filterBookItems(bookItems, searchString, true);
    return [items, items.length];
  }, [bookItems, searchString]);

  const [showModal, setShowModal] = useState(false);
  const [selectedBook, setSelectedBook] = useState<IBook | null>(null);

  const location = useLocation();
  useEffect(() => {
    if (!location.search.includes('modalOpened=true')) {
      setShowModal(false);
    }
  }, [location]);

  const handleItemClick = useCallback(
    (book: IBook) => {
      startTransition(() => {
        setSelectedBook(book);
        setShowModal(true);
      });
      history.push(history.location.pathname + '?modalOpened=true');
    },
    [history]
  );

  const handleViewBook = useCallback(
    (book: IBookshelfItem | undefined) => {
      if (!book) {
        return;
      }
      setShowModal(false);
      history.push(`/viewer/${book.id}`);
    },
    [history]
  );

  const handleModelDidDismiss = useCallback(() => {
    setShowModal(false);
    history.replace(history.location.pathname);
  }, [history]);

  return (
    <>
      <BookList bookItems={filteredItems} onItemClick={handleItemClick} />
      <IonModal isOpen={showModal} onDidDismiss={handleModelDidDismiss}>
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>{selectedBook?.title}</IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  history.goBack();
                }}
              >
                <IonIcon icon={closeOutline} slot="icon-only" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          {selectedBook && (
            <LibraryViewerBookDetail bookDist={bookDict} book={selectedBook} onViewBook={handleViewBook} />
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export const LibraryViewerBookList = memo(LibraryViewerBookListComponent);
