import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSearchbar,
  IonSpinner,
  IonToolbar,
  useIonViewDidLeave,
} from '@ionic/react';
import './Home.css';

import { IWithAppStorageProps, withAppStorage } from '../hocs/withAppStorage';
import { useParams } from 'react-router';
import { useBookDictService } from '../hooks/useBookDictService';
import { LibraryViewerBookList } from '../features/library-viewer/LibraryViewerBookList';
import { useCallback, useState } from 'react';
import { refresh } from 'ionicons/icons';

const DiscoverDetailComponent = ({ appStorage }: IWithAppStorageProps) => {
  const { id } = useParams<{ id: string }>();

  const { bookDict, isFetching, timerRef, refetchBookDict } = useBookDictService(appStorage, id);
  const bookCount = bookDict?.books ? Object.keys(bookDict.books).length : 0;

  useIonViewDidLeave(() => {
    timerRef.current && clearTimeout(timerRef.current);
  });

  const [searchText, setSearchText] = useState<string>('');

  const handleRefresh = useCallback(async () => {
    // Force re-download the bookDict
    await refetchBookDict(true);
  }, [refetchBookDict]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton></IonBackButton>
          </IonButtons>
          <IonSearchbar
            placeholder={`Search ${bookCount > 0 ? `from ${bookCount} books` : ''}`}
            onIonChange={(e) => {
              setSearchText(e.detail.value ?? '');
            }}
            debounce={1000}
          />
          <IonButtons slot="end">
            <IonButton shape="round" onClick={handleRefresh} disabled={isFetching}>
              <IonIcon slot="icon-only" icon={refresh} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isFetching && (
          <div
            className="ion-text-center"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '1rem' }}
          >
            Loading more books from the network&nbsp; <IonSpinner name="circular" />
          </div>
        )}
        {bookDict && (
          <>
            <LibraryViewerBookList key={searchText} bookDict={bookDict} searchString={searchText} />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

const DiscoverDetail = withAppStorage(DiscoverDetailComponent);
export default DiscoverDetail;
