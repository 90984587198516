import { useCallback, useState } from 'react';
import { CheckableListDisplayMode } from './CheckableList';

export interface IUseCheckableListProps {
  initialDisplayMode?: CheckableListDisplayMode;
}

export const useCheckableList = ({ initialDisplayMode }: IUseCheckableListProps = {}) => {
  const [displayMode, setDisplayMode] = useState<CheckableListDisplayMode>(initialDisplayMode || 'Selectable');
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleDisplayModeChange = useCallback((mode: CheckableListDisplayMode) => {
    setDisplayMode(mode);
  }, []);
  const toggleDisplayMode = useCallback(() => {
    setDisplayMode((prevMode) => (prevMode === 'Selectable' ? 'Checkable' : 'Selectable'));
  }, []);

  const handleSelect = useCallback((values: string[]) => {
    setSelectedValues(values);
  }, []);

  const toggleSelectedItem = useCallback(
    (value: string) => {
      if (selectedValues.includes(value)) {
        setSelectedValues((prevValues) => prevValues.filter((v) => v !== value));
      } else {
        setSelectedValues((prevValues) => [...prevValues, value]);
      }
    },
    [selectedValues]
  );

  return {
    displayMode,
    handleDisplayModeChange,
    toggleDisplayMode,
    handleSelect,
    selectedValues,
    toggleSelectedItem,
  };
};
