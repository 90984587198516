import { memo, ReactNode, useCallback, useMemo } from 'react';
import { VirtuosoGrid } from 'react-virtuoso';
import { IBook } from '../../models/IBookDict';
import { BookListStyled } from './BookList.styled';
import { BookListItem } from './BookListItem';

export interface IBookListProps {
  bookItems: IBook[];
  onItemClick?: (book: IBook) => void;
  itemRenderer?: (book: IBook) => ReactNode;
}
const BookListComponent = ({ bookItems, onItemClick, itemRenderer }: IBookListProps) => {
  const itemContent = useCallback(
    (index: number) => {
      const item = bookItems[index];
      return (
        <BookListItem key={item.ebookNo} book={item} onClick={() => onItemClick?.(item)} renderer={itemRenderer} />
      );
    },
    [bookItems, itemRenderer, onItemClick]
  );

  const components = useMemo(() => {
    return {
      Item: BookListStyled.Item,
      List: BookListStyled.ListContainer,
    };
  }, []);

  const style = useMemo(() => {
    return {
      height: '100%',
    };
  }, []);

  if (!bookItems?.length) {
    return null;
  }
  const totalCount = bookItems?.length ?? 0;
  return <VirtuosoGrid style={style} totalCount={totalCount} components={components} itemContent={itemContent} />;
};

export const BookList = memo(BookListComponent);
