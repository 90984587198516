import { IonCheckbox } from '@ionic/react';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CheckBox = styled(IonCheckbox)`
  position: absolute;
  top: 1rem;
  left: 1rem;
`;

export const BookshelfItemStyled = {
  CheckBox,
  Container,
};
