export const EPUB_READER_THEMES = {
  Light: {
    'bg-color': '#fff',
    'bg-color-2nd': '#f5f5f5',
    'text-color': '#000',
    'border-color': '#A0A0A0',
    'border-color-rgb': '160,160,160',
    'box-shadow': '0 0 0 1px #2e2e2e',
    'drop-shadow': 'drop-shadow(0 0 0.5rem rgba(255, 255, 255, 0.5))',
  },
  Dark: {
    'bg-color': '#1e1e1e',
    'bg-color-2nd': '#2e2e2e',
    'text-color': '#fff',
    'border-color': '#A0A0A0',
    'border-color-rgb': '160,160,160',
    'box-shadow': '0 0 0 1px #A0A0A0CC',
    'drop-shadow': 'drop-shadow(0 0 0.5rem #A0A0A07F)',
  },
};

const EPUB_VIEWER_THEMES_DEFAULT = {
  body: {
    'line-height': '200%',
  },
  html: {
    'font-size': '20px',
    'font-family': 'Roboto, sans-serif',
  },
};

export const EPUB_VIEWER_THEMES = {
  Dark: {
    name: 'Dark',
    epubViewerStyles: {
      body: {
        ...EPUB_VIEWER_THEMES_DEFAULT.body,
        'background-color': '#fff',
      },
      html: {
        ...EPUB_VIEWER_THEMES_DEFAULT.html,
        '-webkit-filter': 'invert(1) hue-rotate(180deg)',
        filter: 'invert(1) hue-rotate(180deg)',
      },
      img: { '-webkit-filter': 'invert(1) hue-rotate(180deg)', filter: 'invert(1) hue-rotate(180deg)' },
    },
  },
  Light: {
    name: 'Light',
    epubViewerStyles: {
      body: {
        ...EPUB_VIEWER_THEMES_DEFAULT.body,
        'background-color': '#fff',
      },
      html: {
        ...EPUB_VIEWER_THEMES_DEFAULT.html,
      },
    },
  },
};

export type EpubReaderTheme = keyof typeof EPUB_READER_THEMES;

export const FONT_SIZE_BASE = 100;
export const FONT_SIZE_INITIAL = 33;
export const calculateFontSize = (fontSize: number) => {
  return (fontSize * FONT_SIZE_BASE) / FONT_SIZE_INITIAL;
};
