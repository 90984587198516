import { useEffect, useState } from 'react';
import { appStorage } from '../storage/app.storage';

export interface IWithAppStorageProps {
  appStorage?: Storage;
}

export const withAppStorage =
  <T extends IWithAppStorageProps>(WrappedComponent: React.ComponentType<T>) =>
  (props: T) => {
    const [isStorageReady, setIsStorageReady] = useState(false);

    useEffect(() => {
      const loadStorage = async () => {
        await appStorage.create();
        setIsStorageReady(true);
      };
      loadStorage();
    }, []);

    if (!isStorageReady) {
      return null;
    }
    return <WrappedComponent {...props} appStorage={appStorage} />;
  };
