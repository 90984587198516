export function debounce<A = unknown, R = void>(fn: (args: A) => R, ms = 100): [(args: A) => Promise<R>, () => void] {
  let timer: NodeJS.Timeout;

  const debouncedFunc = (args: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(args));
      }, ms);
    });

  const teardown = () => clearTimeout(timer);

  return [debouncedFunc, teardown];
}

// // declare debounce without promise function
// export function debounce<A = unknown>(fn: (args: A) => void, ms = 100): [(args: A) => void, () => void] {
//   let timer: NodeJS.Timeout;

//   const debouncedFunc = (args: A) => {
//     if (timer) {
//       clearTimeout(timer);
//     }

//     timer = setTimeout(() => {
//       fn(args);
//     }, ms);
//   };

//   const teardown = () => clearTimeout(timer);

//   return [debouncedFunc, teardown];
// }
