export const registerShortPressListener = (
  element: HTMLElement | Document,
  callback: (e: any) => void,
  key: string
) => {
  let startTime: number;
  const win = window as any;
  if (!win) {
    return;
  }
  if (!win.__listeners) {
    win.__listeners = {};
  }

  let startTimer = win.__listeners[`${key}_start`];
  let cancelTimer = win.__listeners[`${key}_end`];

  const unRegister = (_startTimer: any, _cancelTimer: any) => {
    element.removeEventListener('mousedown', _startTimer);
    element.removeEventListener('mouseup', _cancelTimer);
    element.removeEventListener('touchstart', _startTimer);
    element.removeEventListener('touchend', _cancelTimer);
    element.removeEventListener('mouseleave', _cancelTimer);
    startTimer = null;
    cancelTimer = null;
  };

  if (startTimer && cancelTimer) {
    unRegister(startTimer, cancelTimer);
  }

  if (!startTimer) {
    startTimer = win.__listeners[`${key}_start`] = () => {
      startTime = Date.now();
    };
  }

  if (!cancelTimer) {
    cancelTimer = win.__listeners[`${key}_end`] = (e: any) => {
      if (Date.now() - startTime < 300) {
        callback(e);
      }
    };
  }

  element.addEventListener('mousedown', startTimer, false);
  element.addEventListener('mouseup', cancelTimer, false);
  element.addEventListener('touchstart', startTimer, false);
  element.addEventListener('touchend', cancelTimer, false);
  element.addEventListener('mouseleave', cancelTimer, false);

  return () => {
    unRegister(startTimer, cancelTimer);
  };
};
