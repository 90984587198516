import { IonInput } from '@ionic/react';
import styled from 'styled-components';

interface ILoadedState {
  isLoaded?: boolean;
}

export const FooterContainer = styled.div<ILoadedState>`
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
`;

export const PagerBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-left: 0.5rem;
`;

export const PageInput = styled(IonInput)`
  width: 50px;
  border: solid 1px;
  border-radius: 0.5rem;
  input {
    padding: 0.2rem !important;
  }
`;

export const CommandBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const PdfViewerStyled = {
  FooterContainer,
  PagerBar,
  PageInput,
  CommandBar,
};
