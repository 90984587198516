import useSWR from 'swr';

export function useSWRWithLoading<T>(key: string, fetcher: (args_0: string) => Promise<T>) {
  const { data, error, mutate } = useSWR(key, fetcher, {
    shouldRetryOnError: false,
  });

  return {
    data,
    mutate,
    reload: () => mutate(),
    isLoading: !error && typeof data === 'undefined',
    isError: !!error,
    error,
  };
}
