import { IonButton, IonContent, IonIcon, IonText } from '@ionic/react';
import { addCircle, globeOutline } from 'ionicons/icons';
import { memo } from 'react';
import { useHistory } from 'react-router';

export interface IBookshelfEmptyListProps {
  onAddFile: () => void;
}
const BookshelfEmptyListComponent = ({ onAddFile }: IBookshelfEmptyListProps) => {
  const history = useHistory();
  return (
    <IonContent className="ion-padding">
      <IonText>
        <h2>Welcome to here</h2>
        <p>Liberet is an open libary app, where you can download and read thousands of free eBooks</p>
        <p>Start reading by download books from open libaries</p>
        <p>
          <IonButton onClick={() => history.replace('/discover')}>
            Goto Discover <IonIcon slot="end" icon={globeOutline} />
          </IonButton>
        </p>
        <p>Or Add your own eBook files</p>
        <p>
          <IonButton onClick={onAddFile}>
            Add eBook from my device
            <IonIcon slot="end" icon={addCircle} />
          </IonButton>
        </p>
      </IonText>
    </IonContent>
  );
};

export const BookshelfEmptyList = memo(BookshelfEmptyListComponent);
