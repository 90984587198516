import { memo, useCallback, useEffect, useRef, useState, useTransition } from 'react';
import { RenditionOptions } from 'epubjs/types/rendition';
import usePortal from 'react-useportal';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { EpubReader, IEpubReaderRef } from '../../components/epub-reader/EpubReader';
import { useBookStateService } from '../../hooks/useBookStateService';
import { waitUntilTrue } from '../../utils/promise.helper';
import { IBookshelfItem } from '../../models/IBookshelfItem';
import { bookmarkOutline, listOutline, settingsOutline } from 'ionicons/icons';
import { EbookViewerSettings } from './EpubViewerSettings';
import { EpubReaderTheme } from '../../components/epub-reader/EpubReader.constant';
import { IBookState } from '../../models/IBookState';
import { EpubViewerV2Styled } from './EpubViewerV2.styled';
import { useAppTabBarVisibility } from '../../hooks/useAppTabBarVisibility';
import { EpubViewerToc } from './EpubViewerToc';
import { checkIfIsDarkModeEnabled } from '../../utils/ionic.helper';

const DEFAULT_FONT_SIZE = 40;
export interface IEpubViewerV2Props {
  appStorage: Storage | undefined;
  libaryItem: IBookshelfItem;
  bookUrl: string | ArrayBuffer;
  renditionOptions?: RenditionOptions;
  inittialBookState?: IBookState;
  onClose?: () => void;
}
const EpubViewerV2Component = ({
  libaryItem,
  bookUrl,
  inittialBookState,
  renditionOptions,
  appStorage,
  onClose,
}: IEpubViewerV2Props) => {
  const [displayedMenu, setDisplayedMenu] = useState<'settings' | 'toc' | 'bookmark' | undefined>();
  const { getBookStateItem, updateBookStateItem } = useBookStateService(appStorage);
  const [, startTransition] = useTransition();
  const readerRef = useRef<IEpubReaderRef>();
  const [presentLoading, dismissLoading] = useIonLoading();

  const bookId = libaryItem.id;

  const { hideTabBar, showTabBar } = useAppTabBarVisibility();
  const [isLoaded, setIsLoaded] = useState(false);
  const [displayedLocation, setDisplayedLocation] = useState<any>();
  const [displayedChapter, setDisplayedChapter] = useState<string>();

  const updateDisplayedLocation = useCallback(async () => {
    const displayedLocation = await readerRef.current?.getDisplayedLocation();
    const displayedChapter = await readerRef.current?.getCurrentNavItemLabel();
    startTransition(() => {
      setDisplayedLocation(displayedLocation);
      setDisplayedChapter(displayedChapter);
    });
  }, [setDisplayedLocation]);

  useIonViewDidEnter(async () => {
    await presentLoading({
      message: 'Loading...',
      duration: 1500,
    });
    hideTabBar();
    const reader = readerRef.current;
    if (!reader) {
      return;
    }
    const bookStateItem = await getBookStateItem(bookId);
    if (bookStateItem?.location) {
      await waitUntilTrue(() => reader.getIsReady());
      await reader.display(bookStateItem.location);
    }
    await updateDisplayedLocation();
    setIsLoaded(true);
    dismissLoading();
  });
  useIonViewWillLeave(() => {
    setIsLoaded(false);
    showTabBar();
  });

  const handleLocationChange = useCallback(
    async (location: string) => {
      const bookStateItem = await getBookStateItem(bookId);
      await updateBookStateItem({
        ...(bookStateItem ?? { id: bookId }),
        location,
      });
      await updateDisplayedLocation();
    },
    [bookId, getBookStateItem, updateBookStateItem, updateDisplayedLocation]
  );

  const [bookState, setBookState] = useState<IBookState | undefined>(inittialBookState);
  const reloadBookState = useCallback(async () => {
    const bookStateItem = await getBookStateItem(bookId);
    if (!bookStateItem) {
      return;
    }
    setBookState(bookStateItem);
  }, [bookId, getBookStateItem]);

  useEffect(() => {
    reloadBookState();
  }, [reloadBookState]);

  const handleThemeChange = useCallback(
    async (theme: EpubReaderTheme) => {
      await updateBookStateItem({
        id: bookId,
        theme,
      });
      reloadBookState();
    },
    [bookId, reloadBookState, updateBookStateItem]
  );
  useEffect(() => {
    // detect browser theme
    const isDarkMode = checkIfIsDarkModeEnabled();
    if (isDarkMode) {
      handleThemeChange('Dark');
    } else {
      handleThemeChange('Light');
    }
  }, [bookState?.theme, handleThemeChange]);

  const handleFontSizeChange = useCallback(
    async (fontSize: number) => {
      await updateBookStateItem({
        id: bookId,
        fontSize,
      });
      reloadBookState();
    },
    [bookId, reloadBookState, updateBookStateItem]
  );

  const handleShowSettings = useCallback(() => {
    setDisplayedMenu('settings');
  }, []);
  const handleShowTocMenu = useCallback(() => {
    setDisplayedMenu('toc');
  }, []);
  const handleShowBookmarkMenu = useCallback(() => {
    setDisplayedMenu('bookmark');
  }, []);

  const handleMenusClose = useCallback(() => {
    setDisplayedMenu(undefined);
  }, []);

  const readerKey = `reader-${bookId}-${bookState?.theme}-${bookState?.fontSize}`;
  const { Portal } = usePortal();

  return (
    <>
      <IonMenu contentId="bookviewer-main-content" side="end" type="overlay" onIonWillClose={handleMenusClose}>
        {displayedMenu === 'settings' && (
          <EbookViewerSettings
            theme={bookState?.theme}
            fontSize={bookState?.fontSize ?? DEFAULT_FONT_SIZE}
            onThemeChange={handleThemeChange}
            onFontSizeChange={handleFontSizeChange}
          />
        )}
        {displayedMenu === 'toc' && (
          <EpubViewerToc bookId={bookId} appStorage={appStorage} epubReaderRef={readerRef} initialSegmentValue="toc" />
        )}
        {displayedMenu === 'bookmark' && (
          <EpubViewerToc
            bookId={bookId}
            appStorage={appStorage}
            epubReaderRef={readerRef}
            initialSegmentValue="bookmark"
          />
        )}
      </IonMenu>
      <IonPage id="bookviewer-main-content">
        <IonHeader>
          <IonToolbar>
            <IonTitle>{libaryItem.fileMetaData?.title}</IonTitle>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/bookshelf" />
            </IonButtons>
            <IonButtons slot="end">
              <IonMenuToggle>
                <IonButton onClick={handleShowSettings}>
                  <IonIcon icon={settingsOutline} />
                </IonButton>
              </IonMenuToggle>
              <IonMenuToggle>
                <IonButton onClick={handleShowTocMenu}>
                  <IonIcon icon={listOutline} />
                </IonButton>
              </IonMenuToggle>
              <IonMenuToggle>
                <IonButton onClick={handleShowBookmarkMenu}>
                  <IonIcon icon={bookmarkOutline} />
                </IonButton>
              </IonMenuToggle>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <EpubViewerV2Styled.EpubReaderWrapper isLoaded={isLoaded}>
            <EpubReader
              key={readerKey}
              ref={readerRef}
              theme={bookState?.theme}
              fontSize={bookState?.fontSize ?? DEFAULT_FONT_SIZE}
              bookUrl={bookUrl}
              renditionOptions={renditionOptions}
              initialLocation={bookState?.location}
              onLocationChange={handleLocationChange}
            />
          </EpubViewerV2Styled.EpubReaderWrapper>
        </IonContent>
        <Portal>
          <EpubViewerV2Styled.FooterContainer isLoaded={isLoaded}>
            <IonText>
              Page {displayedLocation?.start?.displayed?.page}/{displayedLocation?.start?.displayed?.total} -{' '}
              {displayedChapter}
            </IonText>
          </EpubViewerV2Styled.FooterContainer>
        </Portal>
      </IonPage>
    </>
  );
};

export const EpubViewerV2 = memo(EpubViewerV2Component);
