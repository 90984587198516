import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonToast,
  useIonViewDidEnter,
} from '@ionic/react';

import { addCircleOutline, arrowBackOutline, checkboxOutline, trashOutline } from 'ionicons/icons';
import { IWithAppStorageProps, withAppStorage } from '../hocs/withAppStorage';
import { IBookshelfItem } from '../models/IBookshelfItem';
import { useSWRWithLoading } from '../hooks/useSWRWithLoading';
import { useBookshelfService } from '../hooks/useBookshelfService';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { useHistory } from 'react-router';
import { useCallback, useMemo, useState } from 'react';
import { useCheckableList } from '../components/checkable-list/useCheckableList';
import pluralize from 'pluralize';
import { useAlertDialog } from '../components/dialog/usePromptDialog';
import { BookshelfItemList } from '../features/bookshelf/BookshelfItemList';
import { useBookStateService } from '../hooks/useBookStateService';
import { BookshelfEmptyList } from '../features/bookshelf/BookshelfEmptyList';

const BookShelfComponent = ({ appStorage }: IWithAppStorageProps) => {
  const history = useHistory();
  const [presentToast] = useIonToast();
  const [searchText, setSearchText] = useState<string>('');
  const { getBookshelfItems, batchAddBookshelfItems, batchRemoveBookshelfItems, loadBookshelfItemFromPickedFile } =
    useBookshelfService(appStorage);
  const { getBookStateDict } = useBookStateService(appStorage);
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);
  const { data: bookshelfItems, reload: reloadLibraryItems } = useSWRWithLoading<IBookshelfItem[]>(
    `get bookself items sorted by updatedAt`,
    async () => {
      const [bookshelfItems, bookStateDict] = await Promise.all([getBookshelfItems(), getBookStateDict()]);
      const mergedItems = bookshelfItems.map((item) => {
        const bookState = bookStateDict[item.id];
        return {
          ...item,
          bookState,
        };
      });
      const sortedItems = mergedItems.sort((a, b) => {
        const minDate = new Date(0);
        const aDate = a.bookState?.updatedAt || a.createdAt || minDate;
        const bDate = b.bookState?.updatedAt || b.createdAt || minDate;
        return bDate.getTime() - aDate.getTime();
      });
      return sortedItems;
    }
  );
  useIonViewDidEnter(() => {
    reloadLibraryItems();
  });

  const pickFile = useCallback(async () => {
    const allowFileTypes = ['application/pdf', 'application/epub+zip'];
    const pickFilesResult = await FilePicker.pickFiles({
      types: allowFileTypes,
      multiple: true,
      // readData: true,
    });

    setIsLoadingFiles(true);
    const tasks = pickFilesResult.files.map((file) => {
      if (!allowFileTypes.includes(file.mimeType)) {
        presentToast({
          message: `Unsupported file type: ${file.mimeType}`,
          duration: 3000,
          position: 'top',
        });
        return Promise.reject(new Error(`Unsupported file type: ${file.mimeType}`));
      }
      return loadBookshelfItemFromPickedFile(file);
    });

    const libraryItemsToAdd: IBookshelfItem[] = (await Promise.allSettled(tasks)).reduce((acc, task) => {
      if (task.status === 'fulfilled' && task.value) {
        acc.push(task.value);
      }
      return acc;
    }, [] as IBookshelfItem[]);

    await batchAddBookshelfItems(libraryItemsToAdd);
    reloadLibraryItems();
    setIsLoadingFiles(false);
  }, [batchAddBookshelfItems, loadBookshelfItemFromPickedFile, presentToast, reloadLibraryItems]);

  const handleLibraryItemClicked = useCallback(
    (item: IBookshelfItem) => {
      history.push(`/viewer/${encodeURIComponent(item.id)}`);
    },
    [history]
  );

  const { displayMode, handleDisplayModeChange, toggleDisplayMode, selectedValues, handleSelect, toggleSelectedItem } =
    useCheckableList();
  const alertDeleteItemsButtons = useMemo(
    () => [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
      },
      {
        text: 'Delete',
        handler: async () => {
          await batchRemoveBookshelfItems(selectedValues);
          handleSelect([]);
          reloadLibraryItems();
        },
      },
    ],
    [batchRemoveBookshelfItems, handleSelect, reloadLibraryItems, selectedValues]
  );
  const [alertDeleteItems] = useAlertDialog({
    buttons: alertDeleteItemsButtons,
  });
  const handleDeleteSelectedItems = useCallback(async () => {
    alertDeleteItems(`Are you sure you want to delete ${pluralize('item', selectedValues?.length ?? 0, true)}?`);
  }, [alertDeleteItems, selectedValues?.length]);

  const handleCheckableButtonClick = useCallback(() => {
    toggleDisplayMode();
    handleSelect([]);
  }, [handleSelect, toggleDisplayMode]);

  const hasItems = !!bookshelfItems?.length;
  const hasSelectedItems = selectedValues?.length > 0;
  return (
    <IonPage>
      <IonHeader>
        {displayMode === 'Selectable' && (
          <IonToolbar>
            <IonSearchbar
              placeholder="Search in bookshelf"
              onIonChange={(e) => {
                setSearchText(e.detail.value ?? '');
              }}
              debounce={1000}
            ></IonSearchbar>
            <IonButtons slot="end">
              {hasItems && (
                <IonButton shape="round" onClick={handleCheckableButtonClick}>
                  <IonIcon slot="icon-only" icon={checkboxOutline} />
                </IonButton>
              )}
              <IonButton shape="round" onClick={pickFile} disabled={isLoadingFiles}>
                <IonIcon slot="icon-only" icon={addCircleOutline} hidden={isLoadingFiles} />
                <IonSpinner slot="icon-only" hidden={!isLoadingFiles} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        )}
        {displayMode === 'Checkable' && (
          <IonToolbar>
            <IonTitle>{`${pluralize('item', selectedValues?.length ?? 0, true)} selected`}</IonTitle>
            <IonButtons slot="start">
              <IonButton shape="round" onClick={toggleDisplayMode}>
                <IonIcon slot="icon-only" icon={arrowBackOutline} />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton shape="round" color="danger" onClick={handleDeleteSelectedItems} disabled={!hasSelectedItems}>
                <IonIcon slot="icon-only" icon={trashOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        )}
      </IonHeader>

      {hasItems ? (
        <IonContent fullscreen>
          <BookshelfItemList
            searchString={searchText}
            displayMode={displayMode}
            items={bookshelfItems}
            selectedValues={selectedValues}
            onItemClick={handleLibraryItemClicked}
            onModeChange={handleDisplayModeChange}
            onItemCheckBoxClick={toggleSelectedItem}
          />
        </IonContent>
      ) : (
        <BookshelfEmptyList onAddFile={pickFile} />
      )}
    </IonPage>
  );
};

const BookShelf = withAppStorage(BookShelfComponent);

export default BookShelf;
