import { PDFDocumentProxy } from 'pdfjs-dist';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { PdfPage } from './PdfPage';
import { PdfReaderStyled } from './PdfReader.styled';
import { LogLevel, VirtuosoGrid } from 'react-virtuoso';
import { getPdfPageInCanvas, loadPdf } from '../../utils/pdf.helper';
import { VirtuosoGridHandle } from 'react-virtuoso';
import { getPagesInViewport } from './PdfReader.helper';
import { PDF_VIEWER_THEMES, PdfReaderTheme } from './PdfReader.constant';

export interface IPdfReaderPropsOnScrollArgs {
  scrollTop: number;
  scrollLeft: number;
}
export interface IPdfReaderRef {
  zoom: (zoomValue: number) => void;
  getIsReady: () => boolean;
  scrollTo: (args: IPdfReaderPropsOnScrollArgs) => void;
  scrollToPage: (pageNumber: number) => void;
  getGridScrollerRef: () => React.MutableRefObject<HTMLElement | null>;
  getNumPages: () => number;
  getDisplayedPages: () => { startPage: number; endPage: number };
}

export interface IPdfReaderProps {
  bookData: ArrayBuffer;
  initialScale?: number;
  initialTheme?: PdfReaderTheme;
  onScroll?: (args: IPdfReaderPropsOnScrollArgs) => void;
}
const PdfReaderComponent = forwardRef((props: IPdfReaderProps, ref) => {
  const { bookData, onScroll, initialScale = 1, initialTheme = 'Light' } = props;
  const [pdf, setPdf] = useState<PDFDocumentProxy>();
  const [pageDimensions, setPageDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [scale, setScale] = useState<number>(initialScale);
  const gridRef = useRef<VirtuosoGridHandle>(null);
  const gridScrollerRef = useRef<HTMLElement | null>(null);
  const [themeName, setThemeName] = useState<PdfReaderTheme>(initialTheme);

  useEffect(() => {
    setScale(initialScale);
  }, [initialScale]);

  useEffect(() => {
    setThemeName(initialTheme);
  }, [initialTheme]);

  const handleScrollerRef = useCallback(
    (ref: HTMLElement | null) => {
      gridScrollerRef.current = ref;
      if (!gridScrollerRef.current) return;

      const gridScroller = gridScrollerRef.current;
      // listen for scroll event
      const handleScroll = () => {
        const scrollTop = gridScroller.scrollTop;
        const scrollLeft = gridScroller.scrollLeft;
        onScroll?.({ scrollTop, scrollLeft });
      };
      gridScrollerRef.current.addEventListener('scroll', handleScroll);
    },
    [onScroll]
  );

  const loadPdfFromBookData = useCallback(async () => {
    const pdfDoc = await loadPdf(bookData);

    // if (pdfDoc) {
    //   const outLine = await pdfDoc.getOutline();
    //   console.log('outLine', outLine);
    // }

    setPdf(pdfDoc);
  }, [bookData]);

  useEffect(() => {
    loadPdfFromBookData();
  }, [loadPdfFromBookData]);

  const loadPageDimensions = useCallback(async () => {
    if (!pdf) return;
    const canvas = await getPdfPageInCanvas(pdf, 1, { scale: scale });
    if (canvas) {
      setPageDimensions({ width: canvas.width, height: canvas.height });
    }
  }, [pdf, scale]);

  useEffect(() => {
    loadPageDimensions();
  }, [loadPageDimensions]);

  const zoom = (zoomValue: number) => {
    setScale(zoomValue);
  };

  useImperativeHandle(
    ref,
    () =>
      ({
        zoom,
        getIsReady: () => {
          // check if there is element with class name 'pdf-reader-pdf-page'
          const pdfPageElement = document.querySelector('.pdf-reader-pdf-page');
          return !!pdfPageElement && !!pdf;
        },
        scrollTo: (args: IPdfReaderPropsOnScrollArgs) => {
          if (!gridRef.current || !gridScrollerRef.current) return;
          const { scrollTop, scrollLeft } = args;
          gridRef.current.scrollTo({ top: scrollTop, left: scrollLeft });
        },
        scrollToPage: (pageNumber: number) => {
          if (!gridRef.current || !gridScrollerRef.current) return;
          gridRef.current.scrollToIndex({ index: pageNumber - 1, align: 'start' });
        },
        getGridScrollerRef: () => gridScrollerRef,
        getNumPages: () => pdf?.numPages || 0,
        getDisplayedPages: () => {
          if (!gridScrollerRef.current) return { startPage: 0, endPage: 0 };
          const pageNumbers = getPagesInViewport(gridScrollerRef.current);
          return { startPage: pageNumbers[0], endPage: pageNumbers[pageNumbers.length - 1] };
        },
      } as IPdfReaderRef)
  );

  const itemContent = (pageNumber: number) => {
    if (!pdf) return null;
    const isColorInverted = PDF_VIEWER_THEMES[themeName].pdfPageStyles.isColorInverted;
    return (
      <PdfPage
        className="liberet-pdf-page"
        pdf={pdf}
        pageNumber={pageNumber + 1}
        scale={scale}
        invertColors={isColorInverted}
      />
    );
  };

  // const handleRangeChange = useCallback(
  //   (args: { startIndex: number; endIndex: number }) => {
  //     const { startIndex, endIndex } = args;
  //     const startPage = startIndex + 1;
  //     const endPage = endIndex + 1;
  //     onPageChange?.({ startPage, endPage });
  //   },
  //   [onPageChange]
  // );

  const numPages = pdf?.numPages || 0;
  const overScan = 3 * (pageDimensions?.height ?? 0);

  return (
    <>
      <style>
        {`
          .pdf-reader-pdf-page {
            width: ${pageDimensions.width}px;
            height: ${pageDimensions.height}px;
          }
        `}
      </style>
      {numPages > 0 ? (
        <VirtuosoGrid
          ref={gridRef}
          scrollerRef={handleScrollerRef}
          itemClassName="pdf-reader-pdf-page"
          style={{
            height: '100%',
          }}
          totalCount={numPages}
          components={{
            Item: PdfReaderStyled.Item,
            List: PdfReaderStyled.ListContainer,
          }}
          itemContent={itemContent}
          overscan={overScan}
          logLevel={LogLevel.DEBUG}
        />
      ) : null}
    </>
  );
});

export const PdfReader = memo(PdfReaderComponent);
