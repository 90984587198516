export const checkIfPageIsInViewport = (pageNum: number, scroller: HTMLElement) => {
  const pageElement = scroller.querySelector(`[data-page-num="${pageNum}"]`);
  if (!pageElement) {
    return false;
  }
  const { top, bottom } = pageElement.getBoundingClientRect();
  const { top: scrollerTop, bottom: scrollerBottom } = scroller.getBoundingClientRect();
  if (top > scrollerBottom || bottom < scrollerTop) {
    return false;
  }
  return true;
};

export const getPagesInViewport = (scroller: HTMLElement) => {
  const pages = scroller.querySelectorAll('[data-page-num]');
  const pagesInViewport: number[] = [];
  for (let i = 0; i < pages.length; i += 1) {
    const page: HTMLElement = pages[i] as HTMLElement;
    const dataPageNum = page.getAttribute('data-page-num');
    if (!dataPageNum) {
      continue;
    }
    const pageNum = parseInt(dataPageNum);
    const isInViewport = checkIfPageIsInViewport(pageNum, scroller);
    if (!isInViewport) {
      continue;
    }
    pagesInViewport.push(pageNum);
  }
  return pagesInViewport;
};
