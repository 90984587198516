import { useCallback, useEffect } from 'react';
import { useGlobalLastedBookState } from '../global-state';
import { IAppState, ILastedBook } from '../models/IAppState';
import { useStorageService } from './useStorageService';

const STORAGE_NAME = 'LastedBookDict';

export const useAppStateService = (appStorage: Storage | undefined) => {
  const [lastedBook, setLastedBook] = useGlobalLastedBookState();
  const { getStorageItem, updateStorageItem, removeStorageItem } = useStorageService<IAppState>(
    appStorage,
    STORAGE_NAME
  );

  const getLastedBookItem = useCallback(async () => {
    const lastedBookItem = await getStorageItem('lastedBook');
    setLastedBook(lastedBookItem?.value ?? null);
    return lastedBookItem;
  }, [getStorageItem, setLastedBook]);

  const updateLastedBookItem = useCallback(
    async (book: ILastedBook) => {
      await updateStorageItem({ id: 'lastedBook', value: book });
      setLastedBook(book);
    },
    [setLastedBook, updateStorageItem]
  );

  const removeLastedBookItem = useCallback(async () => {
    await removeStorageItem('lastedBook');
    setLastedBook(null);
  }, [removeStorageItem, setLastedBook]);

  useEffect(() => {
    getLastedBookItem();
  }, [getLastedBookItem]);

  return {
    lastedBook,
    getLastedBookItem,
    updateLastedBookItem,
    removeLastedBookItem,
  };
};
