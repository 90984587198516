import { memo, useCallback, useMemo } from 'react';
import { BookList } from '../../components/book-list/BookList';
import { CheckableListDisplayMode } from '../../components/checkable-list/CheckableList';
import { IBook } from '../../models/IBookDict';
import { IBookshelfItem } from '../../models/IBookshelfItem';
import { convertBookshelfItemToBook } from '../../utils/model-conversion.helpers';
import { filterBookItems } from '../library-viewer/LibraryViewerBookList.helpers';
import { BookshelfItem } from './BookshelfItem';

export interface IBookshelfItemListProps {
  items?: IBookshelfItem[];
  displayMode?: CheckableListDisplayMode;
  onItemClick?: (item: IBookshelfItem) => void;
  onItemSelect?: (item: IBookshelfItem) => void;
  onModeChange?: (mode: CheckableListDisplayMode) => void;
  onItemCheckBoxClick?: (value: string) => void;
  selectedValues?: string[];
  searchString?: string;
}
const BookshelfItemListComponent = ({
  items,
  displayMode,
  selectedValues,
  searchString,
  onItemClick,
  onItemCheckBoxClick,
}: IBookshelfItemListProps) => {
  // build bookItems
  const bookItems: IBook[] = useMemo(() => {
    const books = items?.map((item) => convertBookshelfItemToBook(item)) ?? [];
    const filteredBooks = filterBookItems(books, searchString, false);
    return filteredBooks;
  }, [items, searchString]);

  const handleItemClick = useCallback(
    (item: IBook) => {
      if (displayMode === 'Checkable') return;
      const bookShelfItem = items?.find((i) => i.id === item.ebookNo);
      if (!bookShelfItem) return;
      onItemClick?.(bookShelfItem);
    },
    [displayMode, items, onItemClick]
  );

  const handleItemCheckBoxClick = useCallback(
    (book: IBook) => {
      onItemCheckBoxClick?.(book.ebookNo);
    },
    [onItemCheckBoxClick]
  );

  const itemRenderer = useCallback(
    (item: IBook) => {
      const isChecked = selectedValues?.includes(item.ebookNo) ?? false;
      return (
        <BookshelfItem
          book={item}
          displayMode={displayMode}
          checked={isChecked}
          onCheckClick={handleItemCheckBoxClick}
        />
      );
    },
    [displayMode, handleItemCheckBoxClick, selectedValues]
  );

  return (
    <>
      <BookList bookItems={bookItems} onItemClick={handleItemClick} itemRenderer={itemRenderer} />
    </>
  );
};

export const BookshelfItemList = memo(BookshelfItemListComponent);
