import { IonButton, IonContent, IonHeader, IonIcon, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { globeOutline, library } from 'ionicons/icons';
import { memo } from 'react';
import { useHistory } from 'react-router';

const EmptyBookViewerComponent = () => {
  const history = useHistory();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Start reading</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonText>
          <h2>Glad to see you here</h2>
          <p>Start reading by selecting a book from your bookshelf</p>
          <p>
            <IonButton onClick={() => history.replace('/bookshelf')}>
              Goto Bookshelf
              <IonIcon slot="end" icon={library} />
            </IonButton>
          </p>
          <p>Or discover and download books from networks</p>
          <p>
            <IonButton onClick={() => history.replace('/discover')}>
              Goto Discover <IonIcon slot="end" icon={globeOutline} />
            </IonButton>
          </p>
        </IonText>
      </IonContent>
    </IonPage>
  );
};

export const EmptyBookViewer = memo(EmptyBookViewerComponent);
