import { memo, useCallback, useState } from 'react';
import { generateBookCoverImage } from '../../features/library-viewer/LibraryViewerBookList.helpers';
import { IBook } from '../../models/IBookDict';
import { formatImageSrc } from '../../utils/image-src.helper';
import { BookCoverImageStyled } from './BookCoverImage.styled';

export interface IBookCoverImageProps {
  book: IBook;
}

const BookCoverImageComponent = ({ book }: IBookCoverImageProps) => {
  const initialSrc = book.coverUrl ? formatImageSrc(book.coverUrl) : '';
  const [src, setSrc] = useState(initialSrc);
  const [isLoaded, setIsLoaded] = useState(false);
  const onError = useCallback(() => {
    setSrc(generateBookCoverImage(book.title, book.author ?? '', book.subtitle ?? ''));
  }, [book]);

  const onLoaded = useCallback(() => {
    setIsLoaded(true);
  }, []);

  return (
    <BookCoverImageStyled.Image
      isLoaded={isLoaded}
      onIonImgDidLoad={onLoaded}
      onIonError={onError}
      src={src}
      alt={book.title}
      data-title={book.title}
    />
  );
};

export const BookCoverImage = memo(BookCoverImageComponent);
