import { IToc } from 'react-reader';
import { Rendition } from 'epubjs';
import { DisplayedLocation } from 'epubjs/types/rendition';

export const getTocSection = (toc: IToc, rendition: Rendition) => {
  if (!toc || !rendition) return;
  const section = rendition?.book?.spine?.get(toc.href);

  return section;
};

export const gotoLocation = async (epubCfi: string, rendition: Rendition) => {
  if (!epubCfi || !rendition) return;
  await rendition.display(epubCfi);
};

export const getCurrentDisplayedLocation = async (rendition: Rendition | undefined) => {
  if (!rendition) return;
  const location: DisplayedLocation = await rendition.currentLocation();
  return location;
};

export const getCurrentEpubCfi = async (rendition: Rendition | undefined) => {
  const location: any = await getCurrentDisplayedLocation(rendition);
  return location?.start.cfi as string;
};

export const getEpubTocs = (rendition: Rendition | undefined) => {
  if (!rendition) return;
  return rendition.book.navigation.toc;
};

export const getCurrentNavItemLabel = async (rendition: Rendition | undefined) => {
  if (!rendition) return;
  const locationCfi = await getCurrentEpubCfi(rendition);
  if (!locationCfi) return;
  const spineItem = rendition.book.spine.get(locationCfi);
  const navItem = rendition.book.navigation.get(spineItem.href);

  return navItem?.label || spineItem?.idref || '';
};
