import { IonButton, IonIcon, IonItem, IonList, IonMenuToggle } from '@ionic/react';
import { addCircleOutline, bookmarkOutline, trashOutline } from 'ionicons/icons';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IWithAppStorageProps } from '../../hocs/withAppStorage';
import { useBookStateService } from '../../hooks/useBookStateService';
import { IBookMarkItem } from '../../models/IBookState';
import { IPdfReaderRef } from '../../components/pdf-reader/PdfReader';

export interface IPdfViewerBookmarkItemListProps extends IWithAppStorageProps {
  bookId: string;
  pdfReaderRef: React.MutableRefObject<IPdfReaderRef | undefined>;
}
const PdfViewerBookmarkItemListComponent = ({ bookId, pdfReaderRef, appStorage }: IPdfViewerBookmarkItemListProps) => {
  const [currentItem, setCurrentItem] = useState<IBookMarkItem>();
  const [bookMarkItems, setBookMarkItems] = useState<IBookMarkItem[]>([]);

  const loadCurrentItem = useCallback(async () => {
    const { startPage, endPage } = (await pdfReaderRef.current?.getDisplayedPages()) ?? {
      startPage: 0,
      endPage: 0,
    };
    const currentPage = Math.round((startPage + endPage) / 2);
    setCurrentItem({
      id: currentPage.toString(),
      location: currentPage.toString(),
      displayName: `Page ${currentPage}`,
    });
  }, [pdfReaderRef]);

  useEffect(() => {
    loadCurrentItem();
  }, [loadCurrentItem]);

  const { updateOrInsertBookMarkItem, getBookMarkItems, removeBookMarkItem } = useBookStateService(appStorage);
  const loadBookMarkItems = useCallback(async () => {
    const bookMarkItems = await getBookMarkItems(bookId);
    setBookMarkItems(bookMarkItems ?? []);
  }, [bookId, getBookMarkItems]);

  useEffect(() => {
    loadBookMarkItems();
  }, [loadBookMarkItems]);

  const handleAddBookmark = useCallback(async () => {
    if (!bookId || !currentItem) {
      return;
    }
    await updateOrInsertBookMarkItem(bookId, currentItem);
    await loadBookMarkItems();
  }, [bookId, currentItem, loadBookMarkItems, updateOrInsertBookMarkItem]);

  const bookMarkItemComponents = useMemo(
    () =>
      bookMarkItems.map((item) => {
        const isCurrentItem = item.id === currentItem?.id;
        return (
          <IonItem
            key={item.id}
            button
            detail={false}
            onClick={() => pdfReaderRef?.current?.scrollToPage(parseInt(item.location ?? '0'))}
            disabled={isCurrentItem}
          >
            <IonMenuToggle>{item.displayName}</IonMenuToggle>
            {isCurrentItem ? (
              <IonButton slot="end" fill="clear" shape="round">
                <IonIcon slot="icon-only" icon={bookmarkOutline} />
              </IonButton>
            ) : (
              <IonButton
                slot="end"
                fill="clear"
                shape="round"
                onClick={async (e) => {
                  e.stopPropagation();
                  await removeBookMarkItem(bookId, item.id);
                  await loadBookMarkItems();
                }}
              >
                <IonIcon slot="icon-only" icon={trashOutline} />
              </IonButton>
            )}
          </IonItem>
        );
      }),
    [bookId, bookMarkItems, currentItem?.id, loadBookMarkItems, pdfReaderRef, removeBookMarkItem]
  );

  const currentItemIsAlreadyBookmarked = useMemo(() => {
    return bookMarkItems.some((item) => item.id === currentItem?.id);
  }, [bookMarkItems, currentItem?.id]);
  return (
    <IonList>
      {!currentItemIsAlreadyBookmarked && (
        <IonItem>
          <IonMenuToggle>{currentItem?.displayName}</IonMenuToggle>
          <IonButton slot="end" fill="clear" shape="round" onClick={handleAddBookmark}>
            <IonIcon slot="icon-only" icon={addCircleOutline} />
          </IonButton>
        </IonItem>
      )}
      {bookMarkItemComponents}
    </IonList>
  );
};

export const PdfViewerBookmarkItemList = memo(PdfViewerBookmarkItemListComponent);
