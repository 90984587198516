import styled from 'styled-components';

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  gap: 1rem;
`;

const Item = styled.div`
  overflow: hidden;
  width: min(45vw, 200px);
  aspect-ratio: 2/3;
`;

export const BookListStyled = {
  ListContainer,
  Item,
};
