import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewWillLeave,
  useIonLoading,
} from '@ionic/react';

import { useBookStateService } from '../../hooks/useBookStateService';
import { addCircleOutline, bookmarkOutline, removeCircleOutline } from 'ionicons/icons';
import { IBookState } from '../../models/IBookState';
import { useAppTabBarVisibility } from '../../hooks/useAppTabBarVisibility';
import { IBookshelfItem } from '../../models/IBookshelfItem';
import { IPdfReaderPropsOnScrollArgs, IPdfReaderRef, PdfReader } from '../../components/pdf-reader/PdfReader';
import { debounce } from '../../utils/debounce';
import { waitUntilTrue } from '../../utils/promise.helper';
import usePortal from 'react-useportal';
import { PdfViewerStyled } from './PdfViewer.styled';
import { PdfViewerToc } from './PdfViewerToc';
import { PdfReaderTheme } from '../../components/pdf-reader/PdfReader.constant';
import { checkIfIsDarkModeEnabled } from '../../utils/ionic.helper';

export interface IPdfViewerProps {
  appStorage: Storage | undefined;
  libaryItem: IBookshelfItem;
  bookData: ArrayBuffer;
  inittialBookState?: IBookState;
  onClose?: () => void;
}
const PdfViewerComponent = ({ libaryItem, bookData, inittialBookState, appStorage, onClose }: IPdfViewerProps) => {
  const { Portal } = usePortal();
  const [displayedMenu, setDisplayedMenu] = useState<'settings' | 'toc' | 'bookmark' | undefined>();
  const { getBookStateItem, updateBookStateItem } = useBookStateService(appStorage);
  const currentScaleRef = useRef<number>(1);
  const pdfReaderRef = useRef<IPdfReaderRef>();
  const isPdfReaderReadyRef = useRef<boolean>(false);
  const pageContentRef = useRef<HTMLIonContentElement>(null);
  const [pdfInfo, setPdfInfo] = useState<{ totalPages: number; currentPage: number } | undefined>();
  const [presentLoading, dismissLoading] = useIonLoading();

  const bookId = libaryItem.id;

  const { hideTabBar, showTabBar } = useAppTabBarVisibility();

  useIonViewDidEnter(async () => {
    hideTabBar();
  });
  useIonViewWillLeave(() => {
    showTabBar();
  });

  const [bookState, setBookState] = useState<IBookState | undefined>(inittialBookState);
  const reloadBookState = useCallback(async () => {
    const bookStateItem = await getBookStateItem(bookId);
    if (!bookStateItem) {
      return;
    }
    currentScaleRef.current = bookStateItem.scale ?? 1;
    setBookState(bookStateItem);
  }, [bookId, getBookStateItem]);

  useEffect(() => {
    reloadBookState();
  }, [reloadBookState]);

  useEffect(() => {
    const loadData = async () => {
      await presentLoading({
        message: 'Loading...',
        duration: 1500,
      });
      await waitUntilTrue(() => pdfReaderRef.current?.getIsReady() || false);
      const numPages = pdfReaderRef.current?.getNumPages() ?? 0;
      if (pageContentRef?.current && numPages > 0) {
        // set opacity to 0 to prevent flickering
        pageContentRef.current.style.opacity = '0';
      }

      setPdfInfo({
        totalPages: numPages,
        currentPage: 1,
      });

      setTimeout(async () => {
        const bookStateItem = await getBookStateItem(bookId);
        pdfReaderRef.current?.scrollTo({
          scrollTop: bookStateItem?.scrollTop ?? 0,
          scrollLeft: bookStateItem?.scrollLeft ?? 0,
        });
        isPdfReaderReadyRef.current = true;

        if (pageContentRef?.current) {
          // set opacity to 1 to show the content
          pageContentRef.current.style.opacity = '1';
        }
        await dismissLoading();
        console.log('PdfViewerComponent:loadData:dismissLoading');
      }, 1000);
    };
    loadData();

    // Disable dependency check because we want to run this effect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowTocMenu = useCallback(() => {
    setDisplayedMenu('toc');
  }, []);
  const handleShowBookmarkMenu = useCallback(() => {
    setDisplayedMenu('bookmark');
  }, []);

  const handleMenusClose = useCallback(() => {
    setDisplayedMenu(undefined);
  }, []);

  const handleZoomIn = useCallback(async () => {
    const nextScale = currentScaleRef.current < 3 ? currentScaleRef.current + 0.01 : 3;
    currentScaleRef.current = nextScale;
    if (pdfReaderRef.current) {
      pdfReaderRef.current.zoom(nextScale);
    }
    await updateBookStateItem({
      ...(bookState ?? { id: bookId }),
      scale: nextScale,
    });
  }, [bookId, bookState, updateBookStateItem]);

  const handleZoomOut = useCallback(async () => {
    const nextScale = currentScaleRef.current > 0.5 ? currentScaleRef.current - 0.01 : 0.5;
    currentScaleRef.current = nextScale;
    if (pdfReaderRef.current) {
      pdfReaderRef.current.zoom(nextScale);
    }
    await updateBookStateItem({
      id: bookId,
      scale: nextScale,
    });
  }, [bookId, updateBookStateItem]);

  const [handleScroll] = useMemo(
    () =>
      debounce(async ({ scrollTop, scrollLeft }: IPdfReaderPropsOnScrollArgs) => {
        if (!isPdfReaderReadyRef.current) {
          return;
        }
        await updateBookStateItem({
          id: bookId,
          scrollTop,
          scrollLeft,
        });

        // update current page
        const { startPage, endPage } = (await pdfReaderRef.current?.getDisplayedPages()) ?? {
          startPage: 0,
          endPage: 0,
        };
        setPdfInfo({
          totalPages: pdfReaderRef.current?.getNumPages() ?? 0,
          currentPage: Math.round((startPage + endPage) / 2),
        });
      }),
    [bookId, updateBookStateItem]
  );

  const handlePageInputKeyPressed = useCallback(async (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    const val = `${event.currentTarget?.value || 0}`;
    if (event.key === 'Enter' && !!val) {
      let page = parseInt(val);
      const totalPages = pdfReaderRef.current?.getNumPages() ?? 0;
      if (page < 1) {
        page = 1;
      }
      if (page > totalPages) {
        page = totalPages;
      }
      pdfReaderRef.current?.scrollToPage(page);
    }
  }, []);

  const handleThemeChange = useCallback(
    async (theme: PdfReaderTheme) => {
      await updateBookStateItem({
        id: bookId,
        theme,
      });
      reloadBookState();
    },
    [bookId, reloadBookState, updateBookStateItem]
  );
  useEffect(() => {
    // detect browser theme
    const isDarkMode = checkIfIsDarkModeEnabled();
    if (isDarkMode) {
      handleThemeChange('Dark');
    } else {
      handleThemeChange('Light');
    }
  }, [bookState?.theme, handleThemeChange]);

  const isLoaded = !!pdfInfo;

  return (
    <>
      <IonMenu contentId="bookviewer-main-content" side="end" type="overlay" onIonWillClose={handleMenusClose}>
        {displayedMenu === 'settings' && <></>}
        {displayedMenu === 'toc' && <></>}
        {displayedMenu === 'bookmark' && (
          <PdfViewerToc
            bookId={bookId}
            appStorage={appStorage}
            pdfReaderRef={pdfReaderRef}
            initialSegmentValue="bookmark"
          />
        )}
      </IonMenu>
      <IonPage id="bookviewer-main-content">
        <IonHeader>
          <IonToolbar>
            <IonTitle>{libaryItem.fileMetaData?.title}</IonTitle>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/bookshelf" />
            </IonButtons>
            <IonButtons slot="end">
              {/* <IonMenuToggle>
                <IonButton onClick={handleShowTocMenu}>
                  <IonIcon icon={listOutline} />
                </IonButton>
              </IonMenuToggle> */}
              <IonMenuToggle>
                <IonButton onClick={handleShowBookmarkMenu}>
                  <IonIcon icon={bookmarkOutline} />
                </IonButton>
              </IonMenuToggle>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent ref={pageContentRef} style={{ opacity: 0 }}>
          <PdfReader
            key={bookId}
            bookData={bookData}
            ref={pdfReaderRef}
            initialScale={bookState?.scale}
            initialTheme={bookState?.theme}
            onScroll={handleScroll}
          />
        </IonContent>
        <Portal>
          <PdfViewerStyled.FooterContainer isLoaded={isLoaded}>
            <IonToolbar>
              <PdfViewerStyled.PagerBar slot="start">
                <IonText>Page</IonText>
                <PdfViewerStyled.PageInput
                  type="number"
                  value={pdfInfo?.currentPage ?? 0}
                  onKeyDown={handlePageInputKeyPressed}
                />
                <IonText> / {pdfInfo?.totalPages ?? 0}</IonText>
              </PdfViewerStyled.PagerBar>
              <IonButtons slot="end">
                <IonButton onClick={handleZoomIn}>
                  <IonIcon icon={addCircleOutline} />
                </IonButton>
                <IonButton onClick={handleZoomOut}>
                  <IonIcon icon={removeCircleOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </PdfViewerStyled.FooterContainer>
        </Portal>
      </IonPage>
    </>
  );
};

export const PdfViewer = memo(PdfViewerComponent);
