import { IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import './Home.css';

import { useGlobalIndexService } from '../hooks/useGlobalIndexService';
import { IWithAppStorageProps, withAppStorage } from '../hocs/withAppStorage';
import { DiscoverList } from '../features/discorer-list/DiscoverList';
import pluralize from 'pluralize';

const DiscoverComponent = ({ appStorage }: IWithAppStorageProps) => {
  const { globalIndexDict, isFetching } = useGlobalIndexService(appStorage);

  const totalCount = globalIndexDict?.libaries?.length ?? 0;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Found {pluralize('library', totalCount, true)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {isFetching && (
          <div
            className="ion-text-center"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '1rem' }}
          >
            Loading data&nbsp; <IonSpinner name="circular" />
          </div>
        )}
        <DiscoverList globalIndexDict={globalIndexDict} />
      </IonContent>
    </IonPage>
  );
};

const Discover = withAppStorage(DiscoverComponent);
export default Discover;
