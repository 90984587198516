import { useCallback, useEffect, useRef, useState } from 'react';
import { FetchingStatus } from '../models/enums';
import { IBook, IBookDict } from '../models/IBookDict';
import { IGlobalIndexItem, ILiberetIndexFile } from '../models/IGlobalIndexItem';
import { fetchJson } from '../utils/fetch.helper';
import { useGlobalIndexService } from './useGlobalIndexService';
import { useStorageFlagService } from './useStorageFlagService';
import { useStorageService } from './useStorageService';

const DEFAULT_DICT_ID = 'default';

const buildBookItems = (indexFile: ILiberetIndexFile) => {
  const meta = indexFile.meta;
  const bookItems = indexFile.books.map((book) => {
    const bookItem: IBook = {
      ebookNo: book.ebookNo,
      title: book.title,
      author: book.author,
      language: book.language,
      coverUrl: book.coverUrl || meta?.templates?.coverUrl?.replace(/{ebookNo}/g, book.ebookNo),
      downloadUrl: {
        ...book.downloadUrl,
        // use regex to map the download url to ensure multiple matches
        epub: book.downloadUrl?.epub || meta?.templates?.epubUrl?.replace(/{ebookNo}/g, book.ebookNo),
        mobi: book.downloadUrl?.mobi || meta?.templates?.mobiUrl?.replace(/{ebookNo}/g, book.ebookNo),
        pdf: book.downloadUrl?.pdf || meta?.templates?.pdfUrl?.replace(/{ebookNo}/g, book.ebookNo),
      },
      category: book.category,
      tags: book.tags,
    };
    return bookItem;
  });
  return bookItems;
};

const appendIndexFileToBookDict = (bookDict: IBookDict, indexFile: ILiberetIndexFile) => {
  const bookItems = buildBookItems(indexFile);
  const books = { ...bookDict.books };
  bookItems.forEach((bookItem) => {
    books[bookItem.ebookNo] = bookItem;
  });
  return { ...bookDict, books };
};

export const useBookDictService = (appStorage: Storage | undefined, dictName: string) => {
  const timerRef = useRef<NodeJS.Timeout>();
  const { globalIndexDict } = useGlobalIndexService(appStorage);

  const fullStorageName = `bookDict-${dictName}`;
  const { getStorageItem, updateStorageItem, removeStorageItem } = useStorageService<IBookDict>(
    appStorage,
    fullStorageName
  );
  const { getStorage: getUrlFetchingStatus, setStorage: setUrlFetchingStatus } =
    useStorageFlagService<FetchingStatus>(appStorage);

  const [bookDict, setBookDict] = useState<IBookDict>();
  const [isFetching, setIsFetching] = useState(false);

  const clearBookDictFileFetchingStatus = useCallback(
    async (globalIndexItem: IGlobalIndexItem) => {
      if (!globalIndexItem?.indexFiles?.length) {
        return;
      }
      const indexFiles = globalIndexItem.indexFiles;
      for (const indexFile of indexFiles) {
        if (!indexFile.url) {
          continue;
        }
        await setUrlFetchingStatus(indexFile.url, FetchingStatus.NotFetched);
      }
    },
    [setUrlFetchingStatus]
  );

  const getNextBookDictFileForFetching = useCallback(
    async (globalIndexItem: IGlobalIndexItem) => {
      if (!globalIndexItem?.indexFiles?.length) {
        return;
      }
      const indexFiles = globalIndexItem.indexFiles;
      for (const indexFile of indexFiles) {
        if (!indexFile.url) {
          continue;
        }
        // check if url is being fetched
        const fetchingStatus = await getUrlFetchingStatus(indexFile.url);
        if (fetchingStatus === FetchingStatus.Fetched) {
          continue;
        }
        return indexFile;
      }
    },
    [getUrlFetchingStatus]
  );

  const fetchNextBookDictFileFromNetwork = useCallback(
    async (globalIndexItem: IGlobalIndexItem, fetchNextOnCompleted = true) => {
      setIsFetching(true);
      const nextIndexFile = await getNextBookDictFileForFetching(globalIndexItem);
      if (!nextIndexFile) {
        setIsFetching(false);
        return;
      }
      const indexFileUrl = nextIndexFile.url;
      if (!indexFileUrl) {
        setIsFetching(false);
        return;
      }

      // Fetch index file
      try {
        const indexFileContent: ILiberetIndexFile = await fetchJson(indexFileUrl);
        if (!indexFileContent) {
          return;
        }
        const savedBookDict =
          (await getStorageItem(DEFAULT_DICT_ID)) ||
          ({
            id: DEFAULT_DICT_ID,
            name: dictName,
            books: {},
          } as IBookDict);
        const newBookDict = appendIndexFileToBookDict(savedBookDict, indexFileContent);
        // save to storage
        await updateStorageItem(newBookDict);
        // update state
        setBookDict(newBookDict);
        // set url fetching flag
        setUrlFetchingStatus(indexFileUrl, FetchingStatus.Fetched);

        if (fetchNextOnCompleted) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(() => {
            fetchNextBookDictFileFromNetwork(globalIndexItem, fetchNextOnCompleted);
          }, 500);
        } else {
          setIsFetching(false);
        }
      } catch (error) {
        // set url fetching flag
        setUrlFetchingStatus(indexFileUrl, FetchingStatus.Failed);
      } finally {
      }
    },
    [dictName, getNextBookDictFileForFetching, getStorageItem, setUrlFetchingStatus, updateStorageItem]
  );

  const fetchBookDictFromNetwork = useCallback(
    async (savedBookDict: IBookDict, globalIndexItem: IGlobalIndexItem) => {
      if (!globalIndexItem || !globalIndexItem.indexFiles?.length) {
        return;
      }

      if (!savedBookDict) {
        savedBookDict = {
          id: DEFAULT_DICT_ID,
          name: dictName,
          books: {},
        };
      }
      fetchNextBookDictFileFromNetwork(globalIndexItem);
    },
    [dictName, fetchNextBookDictFileFromNetwork]
  );

  const fetchBookDict = useCallback(
    async (globalIndexItem: IGlobalIndexItem) => {
      // get from storage first
      // there's only one book dict in storage
      const bookDict = await getStorageItem(DEFAULT_DICT_ID);
      if (bookDict) {
        setBookDict(bookDict);
      }
      // also fetch from network to update storage
      fetchBookDictFromNetwork(bookDict, globalIndexItem);
    },
    [fetchBookDictFromNetwork, getStorageItem]
  );

  const removeBookDict = useCallback(
    async (globalIndexItem: IGlobalIndexItem) => {
      // Remove book dict from storage
      await removeStorageItem(DEFAULT_DICT_ID);
      // Clear fetching status
      await clearBookDictFileFetchingStatus(globalIndexItem);
    },
    [clearBookDictFileFetchingStatus, removeStorageItem]
  );

  const refetchBookDict = useCallback(
    async (forceRedownload = false) => {
      if (!globalIndexDict) {
        return;
      }
      const globalIndexItem = globalIndexDict.libaries.find((item) => item.name === dictName);
      if (!globalIndexItem) {
        return;
      }
      if (forceRedownload) {
        await removeBookDict(globalIndexItem);
      }
      // Fetch book dict
      await fetchBookDict(globalIndexItem);

      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    },
    // Do not add fetchBookDict to dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dictName, globalIndexDict]
  );

  useEffect(() => {
    refetchBookDict();
  }, [refetchBookDict]);

  return {
    timerRef,
    bookDict,
    isFetching,
    fetchBookDict,
    refetchBookDict,
  };
};
