import { IonImg } from '@ionic/react';
import styled from 'styled-components';

interface IImageProps {
  isLoaded: boolean;
}

const Image = styled(IonImg)<IImageProps>`
  position: relative;
  // Background color is old book yellow
  height: 100%;
  width: 100%;
  // box shadow so that the image look like 3D book
  padding: 0.5rem;
  &::part(image) {
    object-fit: cover;
    background-color: ${(props: any) => (props.isLoaded ? 'transparent' : '#d9c2a2')};
    /* opacity: ${(props: any) => (props.isLoaded ? 1 : 0)}; */
    box-shadow: 1px 1px 0px #999, 2px 2px 0px #888, 3px 3px 0px #999, 4px 4px 0px #afafaf, 5px 5px 0px #999,
      6px 6px 0px #989898;
    border: 1px solid #888;
  }
  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 6px;
    position: absolute;
    right: 0px;
    bottom: -3px;
    border-top: solid 1px #8b8b8b;
    // rotate 45deg
    transform: rotate(50deg);
  }
  &:before {
    // content from data-title attribute
    content: attr(data-title);
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    top: 0;
    left: 1rem;
    right: 1rem;
    height: 100%;
    text-align: center;
    color: #705a3c;
    font-weight: bold;
    display: ${(props: any) => (props.isLoaded ? 'none' : 'flex')};
  }
`;

export const BookCoverImageStyled = {
  Image,
};
