import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  ${(props) =>
    Object.entries(props.theme)
      .map(([key, value]) => ` --${key}: ${value};`)
      .join('\n')}
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 0, 0, 0.5);
`;

const NavButton = styled.button`
  opacity: 0.2;
  filter: var(--drop-shadow);
  background-color: transparent;
  border: solid 2px var(--border-color);

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  width: 4rem;
  height: 4rem;
  border-radius: 50%;

  &:hover {
    // opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    top: 50%;
    width: 1.5rem;
    height: 1.5rem;
    border-top: 2px solid var(--border-color);
    border-right: 2px solid var(--border-color);
  }
`;

const NavButtonLeft = styled(NavButton)`
  left: 0;

  &:before {
    left: 60%;
    transform: translate(-50%, -50%) rotate(-135deg);
  }
`;

const NavButtonRight = styled(NavButton)`
  right: 0;

  &:before {
    left: 40%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const TocContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: min(100%, 30rem);
  background: var(--bg-color);
  border: solid 1px var(--border-color);
  height: 100%;
  overflow: auto;
`;

const TocList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const TocItem = styled.li`
  color: var(--text-color);
  list-style: none;
  padding: 0.5rem 1rem;
  border-bottom: solid 1px rgba(var(--border-color-rgb), 0.2);
  cursor: pointer;
  &:hover {
    background: var(--bg-color-2nd);
  }
`;

export const EpubReaderStyled = {
  Wrapper,
  Container,
  NavButton,
  NavButtonLeft,
  NavButtonRight,
  TocContainer,
  TocList,
  TocItem,
  Overlay,
};

export default EpubReaderStyled;
