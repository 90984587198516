import { useParams } from 'react-router';
import { BookViewer } from '../features/book-viewer/BookViewer';
import { EmptyBookViewer } from '../features/book-viewer/EmptyBookViewer';
import { IWithAppStorageProps, withAppStorage } from '../hocs/withAppStorage';
import { useAppStateService } from '../hooks/useAppStateService';
import { useBookshelfService } from '../hooks/useBookshelfService';
import { useSWRWithLoading } from '../hooks/useSWRWithLoading';
import { IBookshelfItem } from '../models/IBookshelfItem';

const ViewerComponent = ({ appStorage }: IWithAppStorageProps) => {
  const { id } = useParams<{ id: string }>();
  const { getBookshelfItem: getLibraryItem } = useBookshelfService(appStorage);
  const { getLastedBookItem } = useAppStateService(appStorage);
  const { data: book } = useSWRWithLoading<IBookshelfItem | undefined>(`getLibraryItem(${id})`, async () => {
    if (id) {
      return getLibraryItem(id);
    }
    const lastedBookItem = await getLastedBookItem();
    if (lastedBookItem) {
      return getLibraryItem(lastedBookItem.value.id);
    }
    return;
  });

  if (!book) {
    return <EmptyBookViewer />;
  }

  return <BookViewer book={book} appStorage={appStorage} />;
};

const Viewer = withAppStorage(ViewerComponent);

export default Viewer;
