export const waitUntilTrue = async (func: () => boolean, timeout = 30_000) => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (func()) {
        clearInterval(interval);
        resolve(true);
      }
    }, 100);
    setTimeout(() => {
      clearInterval(interval);
      reject(new Error('Timeout'));
    }, timeout);
  });
};
