import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonMenuToggle,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  SelectChangeEventDetail,
} from '@ionic/react';
import { IonSelectCustomEvent } from '@ionic/core/dist/types/components';
import { memo } from 'react';
import { closeOutline } from 'ionicons/icons';
import { EpubReaderTheme } from '../../components/epub-reader/EpubReader.constant';

export interface IEbookViewerSettingsProps {
  theme?: EpubReaderTheme;
  fontSize?: number;
  onFontSizeChange?: (fontSize: number) => void;
  onThemeChange?: (theme: EpubReaderTheme) => void;
}
const EbookViewerSettingsComponent = ({
  fontSize = 50,
  theme,
  onThemeChange,
  onFontSizeChange,
}: IEbookViewerSettingsProps) => {
  const handleThemeChange = (event: IonSelectCustomEvent<SelectChangeEventDetail<EpubReaderTheme>>) => {
    onThemeChange?.(event.detail.value);
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
          <IonButtons slot="end">
            <IonMenuButton>
              <IonMenuToggle>
                <IonIcon icon={closeOutline} />
              </IonMenuToggle>
            </IonMenuButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem disabled>
            <IonLabel position="stacked">Theme</IonLabel>
            <IonSelect interface="popover" placeholder="Select Theme" value={theme} onIonChange={handleThemeChange}>
              <IonSelectOption value="Light">Light</IonSelectOption>
              <IonSelectOption value="Dark">Dark</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Font Size</IonLabel>
            <IonRange
              value={fontSize}
              onIonChange={({ detail }) => onFontSizeChange?.(detail.value as number)}
              debounce={500}
            ></IonRange>
          </IonItem>
        </IonList>
      </IonContent>
    </>
  );
};

export const EbookViewerSettings = memo(EbookViewerSettingsComponent);
