import { memo, useEffect } from 'react';
import { IWithAppStorageProps } from '../../hocs/withAppStorage';
import { useAppStateService } from '../../hooks/useAppStateService';
import { useBookStateService } from '../../hooks/useBookStateService';
import { useSWRWithLoading } from '../../hooks/useSWRWithLoading';
import { IBookshelfItem } from '../../models/IBookshelfItem';
import { EpubViewerV2 } from '../epub-viewer/EpubViewerV2';
import { PdfViewer } from '../pdf-viewer/PdfViewer';

export interface IBookViewerProps extends IWithAppStorageProps {
  book: IBookshelfItem;
  onClose?: () => void;
}
const BookViewerComponent = ({ book, appStorage, onClose }: IBookViewerProps) => {
  const { updateLastedBookItem } = useAppStateService(appStorage);
  const { getBookStateItem } = useBookStateService(appStorage);
  const { data } = useSWRWithLoading(`LoadBook(${book?.id})`, async () => {
    if (!book) {
      return;
    }
    const fileBuffer = await book.blob?.arrayBuffer();
    const bookState = (await getBookStateItem(book.id)) ?? null;

    return { fileBuffer, bookState };
  });

  useEffect(() => {
    updateLastedBookItem({
      id: book?.id,
      name: book?.name ?? '',
    });
  }, [book?.id, book?.name, updateLastedBookItem]);

  if (!data?.fileBuffer || !book?.id) {
    return <></>;
  }

  const isEpub = book?.type === 'application/epub+zip';
  const isPdf = book?.type === 'application/pdf';

  return isEpub ? (
    <EpubViewerV2
      key={book.id}
      libaryItem={book}
      bookUrl={data.fileBuffer}
      appStorage={appStorage}
      inittialBookState={data?.bookState}
      onClose={onClose}
    />
  ) : isPdf ? (
    <PdfViewer
      key={book.id}
      libaryItem={book}
      bookData={data.fileBuffer}
      appStorage={appStorage}
      inittialBookState={data?.bookState}
      onClose={onClose}
    />
  ) : (
    <></>
  );
};

export const BookViewer = memo(BookViewerComponent);
