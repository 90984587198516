import { memo, useCallback } from 'react';
import { BookCoverImage } from '../../components/book-list/BookCoverImage';
import { CheckableListDisplayMode } from '../../components/checkable-list/CheckableList';
import { IBook } from '../../models/IBookDict';
import { BookshelfItemStyled } from './BookshelfItem.styled';

export interface IBookshelfItemProps {
  book: IBook;
  displayMode?: CheckableListDisplayMode;
  checked?: boolean;
  onCheckClick?: (book: IBook) => void;
}
const BookshelfItemComponent = (props: IBookshelfItemProps) => {
  const { book, displayMode = 'Selectable', checked, onCheckClick } = props;
  const isCheckable = displayMode === 'Checkable';

  const handleCheckBoxClick = useCallback(
    (e: React.MouseEvent<HTMLIonCheckboxElement, MouseEvent>) => {
      e.stopPropagation();
      onCheckClick?.(book);
    },
    [book, onCheckClick]
  );

  return (
    <BookshelfItemStyled.Container>
      {isCheckable && <BookshelfItemStyled.CheckBox onClick={handleCheckBoxClick} checked={checked} />}
      <BookCoverImage book={book} />
    </BookshelfItemStyled.Container>
  );
};

export const BookshelfItem = memo(BookshelfItemComponent);
