import styled from 'styled-components';
import { LoadingButton } from '../../components/button/LoadingButton';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  justify-content: center;
`;

const CoverWrapper = styled.div`
  overflow: hidden;
  width: min(45vw, 200px);
  aspect-ratio: 2/3;

  align-self: center;
`;

const DetailWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: min(80vw, 300px);
  flex-grow: 1;
  align-items: center;
  text-align: center;
`;

const Title = styled.h3`
  margin: 0.5rem 0 1rem 0;
`;
const Subtitle = styled.h4``;
const Author = styled.p`
  opacity: 0.5;
  margin: 0;
  text-transform: capitalize;
`;
const DownloadWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const DownloadButton = styled(LoadingButton)``;

export const LibraryViewerBookDetailStyled = {
  Container,
  CoverWrapper,
  DetailWrapper,
  Title,
  Subtitle,
  Author,
  DownloadWrapper,
  DownloadButton,
};
