import { Http, HttpOptions } from '@capacitor-community/http';
import { IPFS_GATEWAY_TIMEOUT } from '../constants/app.constant';
import { base64ToUint8Array } from './encryption.helper';

export const fetchWithCapacitorHttp = async (url: string, options?: HttpOptions): Promise<Uint8Array | undefined> => {
  const resp = await Http.request({
    ...options,
    url,
    method: 'GET',
    responseType: 'blob',
    connectTimeout: 10_000,
    readTimeout: IPFS_GATEWAY_TIMEOUT,
  });

  if (resp.status === 200) {
    // if response is string, it should be base64 encoded
    if (typeof resp.data === 'string') {
      const dataInBase64 = await resp.data;
      const dataInUint8Array = base64ToUint8Array(dataInBase64);
      return dataInUint8Array;
    }
    // otherwise, it should be object
    const dataInString = JSON.stringify(resp.data);
    const dataInUint8Array = new TextEncoder().encode(dataInString);
    return dataInUint8Array;
  }
  return undefined;
};
