import { IonImg } from '@ionic/react';
import styled from 'styled-components';

const ListItemImageWrapper = styled.div`
  max-width: min(30vw, 150px);
`;
const ListItemImage = styled(IonImg)`
  object-fit: contain;
  border-radius: 0.5rem;
  &::part(image) {
    border-radius: 4px;
  }
`;

export const DiscoverListStyled = { ListItemImage, ListItemImageWrapper };
