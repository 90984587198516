export const PDF_VIEWER_THEMES = {
  Dark: {
    name: 'Dark',
    pdfPageStyles: {
      isColorInverted: true,
    },
  },
  Light: {
    name: 'Light',
    pdfPageStyles: {
      isColorInverted: false,
    },
  },
};

export type PdfReaderTheme = keyof typeof PDF_VIEWER_THEMES;
