import { useCallback } from 'react';
import { IBookMarkItem, IBookState } from '../models/IBookState';
import { useStorageService } from './useStorageService';

const STORAGE_NAME = 'bookStateDict';

export const useBookStateService = (appStorage: Storage | undefined) => {
  const {
    getStorageDict,
    getStorageItem,
    getStorageItems,
    addStorageItem,
    updateStorageItem,
    removeStorageItem,
    batchAddStorageItems,
    batchRemoveStorageItems,
  } = useStorageService<IBookState>(appStorage, STORAGE_NAME);

  const updateBookLocation = useCallback(
    async (bookId: string, location: string) => {
      return updateStorageItem({ id: bookId, location });
    },
    [updateStorageItem]
  );

  const updateOrInsertBookMarkItem = useCallback(
    async (bookId: string, bookMarkItem: IBookMarkItem) => {
      const bookState = await getStorageItem(bookId);
      const bookMarks = bookState?.bookmarks || [];
      const currentItem = bookMarks.find((item) => item.id === bookMarkItem.id);
      if (currentItem) {
        currentItem.location = bookMarkItem.location;
        currentItem.displayName = bookMarkItem.displayName;
        currentItem.updatedAt = new Date();
      } else {
        bookMarks.unshift({
          ...bookMarkItem,
          createdAt: new Date(),
        });
      }
      return updateStorageItem({ id: bookId, bookmarks: bookMarks });
    },
    [getStorageItem, updateStorageItem]
  );

  const getBookMarkItems = useCallback(
    async (bookId: string) => {
      const bookState = await getStorageItem(bookId);
      const items = bookState?.bookmarks || [];
      // sort by updatedAt
      return items.sort((a, b) => {
        if (a.updatedAt && b.updatedAt) {
          return b.updatedAt.getTime() - a.updatedAt.getTime();
        }
        return 0;
      });
    },
    [getStorageItem]
  );

  const removeBookMarkItem = useCallback(
    async (bookId: string, bookMarkId: string) => {
      const bookState = await getStorageItem(bookId);
      const bookMarks = bookState?.bookmarks || [];
      const newBookMarks = bookMarks.filter((item) => item.id !== bookMarkId);
      return updateStorageItem({ id: bookId, bookmarks: newBookMarks });
    },
    [getStorageItem, updateStorageItem]
  );

  return {
    getBookStateDict: getStorageDict,
    getBookStateItems: getStorageItems,
    getBookStateItem: getStorageItem,
    addBookStateItem: addStorageItem,
    updateBookStateItem: updateStorageItem,
    batchAddBookStateItems: batchAddStorageItems,
    removeBookStateItem: removeStorageItem,
    batchRemoveBookStateItems: batchRemoveStorageItems,
    updateBookLocation,
    updateOrInsertBookMarkItem,
    getBookMarkItems,
    removeBookMarkItem,
  };
};
