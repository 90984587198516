import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { ButtonHTMLAttributes, memo, PropsWithChildren } from 'react';
import { JSX } from '@ionic/core';

//define interface for props which extends from IonButton and HTMLAttribute

export type ILoadingButtonProps = JSX.IonButton &
  ButtonHTMLAttributes<HTMLIonButtonElement> & {
    isLoading?: boolean;
    icon?: string;
    iconSlot?: string;
  };
const LoadingButtonComponent = ({
  isLoading,
  disabled,
  children,
  icon,
  iconSlot,
  ...props
}: PropsWithChildren<ILoadingButtonProps>) => {
  const iconSlotName = iconSlot || 'end';
  return (
    <IonButton disabled={disabled || isLoading} {...props}>
      {children}
      {isLoading && <IonSpinner slot={iconSlotName} name="circular" />}
      {!isLoading && icon && <IonIcon slot={iconSlotName} icon={icon} />}
    </IonButton>
  );
};

export const LoadingButton = memo(LoadingButtonComponent);
