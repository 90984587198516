import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface IStorageItem {
  id: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export const useStorageService = <T extends IStorageItem>(appStorage: Storage | undefined, storageName: string) => {
  type IDict = Record<string, T>;
  if (!appStorage) {
    throw new Error('appStorage is undefined');
  }
  const getStorageDict = useCallback(async (): Promise<IDict> => {
    const dict = await appStorage.get(storageName);
    return dict || {};
  }, [appStorage, storageName]);

  const setStorageDict = useCallback(
    async (dict: IDict) => {
      await appStorage.set(storageName, dict);
    },
    [appStorage, storageName]
  );

  const getStorageItems = useCallback(async () => {
    const dict = await getStorageDict();
    const items = Object.values(dict);
    return items || [];
  }, [getStorageDict]);

  const getStorageItem = useCallback(
    async (id: string) => {
      const dict = await getStorageDict();
      return dict[id];
    },
    [getStorageDict]
  );

  const addStorageItem = useCallback(
    async (item: T, override = true, storageDict?: IDict) => {
      if (!item.id) {
        item.id = uuidv4();
      }
      const dict = storageDict ?? (await getStorageDict());
      const currentItem = dict[item.id];
      if (!override && currentItem) {
        return currentItem;
      }
      const createdAt = new Date();
      dict[item.id] = { ...item, createdAt };
      await setStorageDict(dict);
      return getStorageItem(item.id);
    },
    [getStorageDict, getStorageItem, setStorageDict]
  );

  const updateStorageItem = useCallback(
    async (item: T, storageDict?: IDict) => {
      const dict = storageDict ?? (await getStorageDict());

      const createdAt = new Date();
      const currentItem = dict[item.id] ?? { createdAt };

      const updatedAt = new Date();
      dict[item.id] = { ...currentItem, ...item, updatedAt };
      await setStorageDict(dict);
      return getStorageItem(item.id);
    },
    [getStorageDict, getStorageItem, setStorageDict]
  );

  const batchAddStorageItems = useCallback(
    async (items: T[], override = true) => {
      const dict = await getStorageDict();
      const newItems = items.map((item) => {
        if (!item.id) {
          item.id = uuidv4();
        }
        const currentItem = dict[item.id];
        if (!override && currentItem) {
          return currentItem;
        }
        item.createdAt = currentItem?.createdAt || new Date();
        item.updatedAt = new Date();
        dict[item.id] = item;
        return item;
      });
      const newDict = {
        ...dict,
        ...newItems.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {} as IDict),
      };
      await setStorageDict(newDict);

      return newItems;
    },
    [getStorageDict, setStorageDict]
  );

  const removeStorageItem = useCallback(
    async (id: string) => {
      const dict = await getStorageDict();
      delete dict[id];
      setStorageDict(dict);
    },
    [getStorageDict, setStorageDict]
  );

  const batchRemoveStorageItems = useCallback(
    async (ids: string[]) => {
      const dict = await getStorageDict();
      ids.forEach((id) => {
        delete dict[id];
      });
      setStorageDict(dict);
    },
    [getStorageDict, setStorageDict]
  );

  return {
    getStorageDict,
    getStorageItems,
    getStorageItem,
    addStorageItem,
    updateStorageItem,
    batchAddStorageItems,
    removeStorageItem,
    batchRemoveStorageItems,
  };
};
