import { IonButton, IonIcon, IonItem, IonList, IonMenuToggle } from '@ionic/react';
import { v4 as uuidv4 } from 'uuid';
import { addCircleOutline, bookmarkOutline, trashOutline } from 'ionicons/icons';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IEpubReaderRef } from '../../components/epub-reader/EpubReader';
import { IWithAppStorageProps } from '../../hocs/withAppStorage';
import { useBookStateService } from '../../hooks/useBookStateService';
import { IBookMarkItem } from '../../models/IBookState';

export interface IEpubViewerBookmarkItemListProps extends IWithAppStorageProps {
  bookId: string;
  epubReaderRef: React.MutableRefObject<IEpubReaderRef | undefined>;
}
const EpubViewerBookmarkItemListComponent = ({
  bookId,
  epubReaderRef,
  appStorage,
}: IEpubViewerBookmarkItemListProps) => {
  const [currentItem, setCurrentItem] = useState<IBookMarkItem>();
  const [bookMarkItems, setBookMarkItems] = useState<IBookMarkItem[]>([]);

  const loadCurrentItem = useCallback(async () => {
    const currentCfi = await epubReaderRef?.current?.getCurrentLocation();
    const displayedLocation: any = await epubReaderRef?.current?.getDisplayedLocation();
    const displayedChapter = await epubReaderRef?.current?.getCurrentNavItemLabel();
    setCurrentItem({
      id: currentCfi || uuidv4(),
      location: currentCfi,
      displayName: `Page ${displayedLocation?.start?.displayed?.page}/${displayedLocation?.start?.displayed?.total} - 
    ${displayedChapter}`,
    });
  }, [epubReaderRef]);

  useEffect(() => {
    loadCurrentItem();
  }, [loadCurrentItem]);

  const { updateOrInsertBookMarkItem, getBookMarkItems, removeBookMarkItem } = useBookStateService(appStorage);
  const loadBookMarkItems = useCallback(async () => {
    const bookMarkItems = await getBookMarkItems(bookId);
    setBookMarkItems(bookMarkItems ?? []);
  }, [bookId, getBookMarkItems]);

  useEffect(() => {
    loadBookMarkItems();
  }, [loadBookMarkItems]);

  const handleAddBookmark = useCallback(async () => {
    if (!bookId || !currentItem) {
      return;
    }
    await updateOrInsertBookMarkItem(bookId, currentItem);
    await loadBookMarkItems();
  }, [bookId, currentItem, loadBookMarkItems, updateOrInsertBookMarkItem]);

  const bookMarkItemComponents = useMemo(
    () =>
      bookMarkItems.map((item) => {
        const isCurrentItem = item.id === currentItem?.id;
        return (
          <IonItem
            key={item.id}
            button
            detail={false}
            onClick={() => epubReaderRef?.current?.display(item.location ?? '')}
            disabled={isCurrentItem}
          >
            <IonMenuToggle>{item.displayName}</IonMenuToggle>
            {isCurrentItem ? (
              <IonButton slot="end" fill="clear" shape="round">
                <IonIcon slot="icon-only" icon={bookmarkOutline} />
              </IonButton>
            ) : (
              <IonButton
                slot="end"
                fill="clear"
                shape="round"
                onClick={async (e) => {
                  e.stopPropagation();
                  await removeBookMarkItem(bookId, item.id);
                  await loadBookMarkItems();
                }}
              >
                <IonIcon slot="icon-only" icon={trashOutline} />
              </IonButton>
            )}
          </IonItem>
        );
      }),
    [bookId, bookMarkItems, currentItem?.id, epubReaderRef, loadBookMarkItems, removeBookMarkItem]
  );

  const currentItemIsAlreadyBookmarked = useMemo(() => {
    return bookMarkItems.some((item) => item.id === currentItem?.id);
  }, [bookMarkItems, currentItem?.id]);
  return (
    <IonList>
      {!currentItemIsAlreadyBookmarked && (
        <IonItem>
          <IonMenuToggle>{currentItem?.displayName}</IonMenuToggle>
          <IonButton slot="end" fill="clear" shape="round" onClick={handleAddBookmark}>
            <IonIcon slot="icon-only" icon={addCircleOutline} />
          </IonButton>
        </IonItem>
      )}
      {bookMarkItemComponents}
    </IonList>
  );
};

export const EpubViewerBookmarkItemList = memo(EpubViewerBookmarkItemListComponent);
