export function invertCanvasColors(canvas: HTMLCanvasElement): void {
  const context = canvas.getContext('2d');
  if (!context) {
    return;
  }

  // Get the image data of the canvas
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  // Iterate over each pixel and invert its color
  for (let i = 0; i < data.length; i += 4) {
    data[i] = 255 - data[i]; // Red
    data[i + 1] = 255 - data[i + 1]; // Green
    data[i + 2] = 255 - data[i + 2]; // Blue
    // Note: data[i + 3] represents the alpha value, we're not modifying it here
  }

  // Put the inverted image data back onto the canvas
  context.putImageData(imageData, 0, 0);
}
