import styled from 'styled-components';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  gap: 0.2rem;
  align-items: center;
`;

const Item = styled.div``;

export const PdfReaderStyled = {
  ListContainer,
  Item,
};
