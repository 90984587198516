import { IBook } from '../models/IBookDict';
import { IBookshelfItem } from '../models/IBookshelfItem';

export const convertBookshelfItemToBook = (bookshelfItem: IBookshelfItem): IBook => {
  const book: IBook = {
    ebookNo: bookshelfItem.id,
    type: bookshelfItem.type,
    title: bookshelfItem.fileMetaData?.title ?? '',
    subtitle: bookshelfItem.fileMetaData?.subtitle ?? '',
    author: bookshelfItem.fileMetaData?.creator ?? '',
    language: bookshelfItem.fileMetaData?.language ?? '',
    coverUrl:
      bookshelfItem.fileMetaData?.cover ||
      (bookshelfItem.fileMetaData?.coverBlob ? URL.createObjectURL(bookshelfItem.fileMetaData?.coverBlob) : ''),
  };
  return book;
};
