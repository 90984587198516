import { create, IPFS } from 'ipfs-core';
import * as Name from 'w3name';
import { IPFS_GATEWAYS, IPFS_TIMEOUT, IPNS_GATEWAYS } from '../constants/app.constant';
import { fetchWithCapacitorHttp } from './http.helper';

export async function createIpfsNode() {
  const win: any = window || globalThis;
  const ipfs: IPFS = win.__liberetIpfsNode ?? (await create());
  win.__liberetIpfsNode = ipfs;
  return ipfs;
}

export async function fetchIpfsFileFromSwarm(fileCID: string): Promise<Uint8Array | undefined> {
  console.log(`Fetching IPFS file ${fileCID}...`);

  try {
    const ipfs = await createIpfsNode();

    const chunks: Uint8Array[] = [];
    for await (const chunk of ipfs.cat(fileCID, { timeout: IPFS_TIMEOUT })) {
      chunks.push(chunk);
    }
    // merge all chunks into one Uint8Array
    const file = new Uint8Array(chunks.reduce((acc, chunk) => acc + chunk.length, 0));
    let offset = 0;
    for (const chunk of chunks) {
      file.set(chunk, offset);
      offset += chunk.length;
    }
    console.log(`Completed fetching IPFS file ${fileCID}.`);
    return file;
  } catch (error: any) {
    console.log(`Failed to fetch IPFS file ${fileCID}.`, error);
    // if timeout, throw error
    if (error.name === 'TimeoutError') {
      throw error;
    }
    // otherwise, return undefined
    return undefined;
  }
}

export function buildIpfsHttpUrl(fileCID: string, gatewayUrl: string, cidType: 'ipfs' | 'ipns' = 'ipfs') {
  // split name hash and path
  const [nameHash, path] = fileCID.split('/');
  // replace {cid} and  {path} in gateway url
  let url = gatewayUrl
    .replace('{cid}', nameHash)
    .replace('{path}', path ? encodeURIComponent(path) : '')
    .replace('{type}', cidType);
  if (url.endsWith('/')) {
    url = url.slice(0, -1);
  }
  return url;
}

export async function fetchIpfsFileFromGateway(fileCID: string, gatewayUrl: string, cidType: 'ipfs' | 'ipns' = 'ipfs') {
  // replace {cid} and  {path} in gateway url
  const url = buildIpfsHttpUrl(fileCID, gatewayUrl, cidType);
  console.log(`Fetching url ${url}...`);
  // fetch file from gateway
  const response = await fetchWithCapacitorHttp(url);
  return response;
}

export async function tryGetIPFSFileFromGateways(
  fileCID: string,
  gatewayUrls: string[],
  cidType: 'ipfs' | 'ipns' = 'ipfs'
) {
  for (const gatewayUrl of gatewayUrls) {
    try {
      console.log(`Trying to fetch ${cidType} file ${fileCID} from ${gatewayUrl}...`);
      const file = await fetchIpfsFileFromGateway(fileCID, gatewayUrl, cidType);
      if (file) {
        return file;
      }
    } catch (error) {
      console.log(`Failed to fetch ${cidType} file ${fileCID} from ${gatewayUrl}.`, error);
    }
  }
  return undefined;
}

export async function fetchIpfsFile(fileCID: string): Promise<Uint8Array | undefined> {
  // try to fetch from gateways
  const gatewayUrls = IPFS_GATEWAYS;
  const file = await tryGetIPFSFileFromGateways(fileCID, gatewayUrls);
  if (file) {
    return file;
  }

  // try to fetch from swarm
  try {
    const file = await fetchIpfsFileFromSwarm(fileCID);
    if (file) {
      return file;
    }
  } catch (error) {
    console.log(`Failed to fetch IPFS file ${fileCID} from swarm.`);
  }
}

export async function tryFetchIpnsFileFromGateways(uri: string) {
  // try to fetch from gateways
  const gatewayUrls = IPNS_GATEWAYS;
  const file = await tryGetIPFSFileFromGateways(uri, gatewayUrls, 'ipns');
  if (file) {
    return file;
  }
  return undefined;
}

export async function fetchIpnsFileAfterResolvingName(uri: string) {
  // split name hash and path
  const [nameHash, path] = uri.split('/');

  // resolve name hash to CID
  const name = Name.parse(nameHash);
  const revision = await Name.resolve(name);
  const cid = `${revision.value}${path ? '/' + path : ''}`;
  console.log(`Resolved IPNS name ${nameHash} to IPFS CID ${cid}.`);

  return fetchIpfsFile(cid);
}

export async function fetchIpnsFile(uri: string) {
  // try to fetch from gateways
  let file = await tryFetchIpnsFileFromGateways(uri);
  if (file) {
    return file;
  }

  // try to fetch from swarm
  file = await fetchIpnsFileAfterResolvingName(uri);
  if (file) {
    return file;
  }

  return undefined;
}
