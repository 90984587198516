import { useCallback } from 'react';

export const useAppTabBarVisibility = () => {
  const hideTabBar = useCallback((): void => {
    const tabBar = document.getElementsByTagName('ion-tab-bar')[0] as HTMLElement;
    if (tabBar) {
      // tabBar.style.display = 'none';
      tabBar.style.visibility = 'hidden';
    }
  }, []);

  const showTabBar = useCallback((): void => {
    const tabBar = document.getElementsByTagName('ion-tab-bar')[0] as HTMLElement;
    if (tabBar) {
      tabBar.style.visibility = 'visible';
    }
  }, []);

  return {
    hideTabBar,
    showTabBar,
  };
};
