import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonIcon,
  IonContent,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { memo, useCallback, useEffect, useState } from 'react';
import { PdfViewerBookmarkItemList } from './PdfViewerBookmarkItemList';
import { SegmentChangeEventDetail } from '@ionic/core';
import { IonSegmentCustomEvent } from '@ionic/core/dist/types/components';
import { IWithAppStorageProps } from '../../hocs/withAppStorage';
import { IPdfReaderRef } from '../../components/pdf-reader/PdfReader';

export interface IPdfViewerTocProps extends IWithAppStorageProps {
  bookId: string;
  pdfReaderRef: React.MutableRefObject<IPdfReaderRef | undefined>;
  initialSegmentValue?: 'toc' | 'bookmark';
}
const PdfViewerTocComponent = ({ bookId, pdfReaderRef, initialSegmentValue, appStorage }: IPdfViewerTocProps) => {
  const [segmentValue, setSegmentValue] = useState<'toc' | 'bookmark'>(initialSegmentValue || 'toc');
  const handleSegmentChange = useCallback((event: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
    setSegmentValue(event.detail.value as 'toc' | 'bookmark');
  }, []);

  useEffect(() => {
    setSegmentValue(initialSegmentValue || 'toc');
  }, [initialSegmentValue]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonSegment slot="start" value={segmentValue} onIonChange={handleSegmentChange}>
            {/* <IonSegmentButton value="toc">
              <IonLabel>TOC</IonLabel>
            </IonSegmentButton> */}
            <IonSegmentButton value="bookmark">
              <IonLabel>Bookmarks</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonButtons slot="end">
            <IonMenuButton>
              <IonMenuToggle>
                <IonIcon icon={closeOutline} />
              </IonMenuToggle>
            </IonMenuButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/* {segmentValue === 'toc' && <PdfViewerTocItemList epubReaderRef={epubReaderRef} />} */}
        {segmentValue === 'bookmark' && (
          <PdfViewerBookmarkItemList pdfReaderRef={pdfReaderRef} bookId={bookId} appStorage={appStorage} />
        )}
      </IonContent>
    </>
  );
};

export const PdfViewerToc = memo(PdfViewerTocComponent);
