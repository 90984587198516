import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Viewer from './pages/Viewer';
import { globeOutline, library } from 'ionicons/icons';
import BookShelf from './pages/BookShelf';
import Discover from './pages/Discover';
import { AppBarViewerButton } from './components/app-bar/AppBarViewerButton';
import DiscoverDetail from './pages/DiscoverDetail';

setupIonicReact();

const AppComponent: React.FC = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route exact path="/:tab(bookshelf)" component={BookShelf} />
        <Route exact path="/:tab(viewer)" component={Viewer} />
        <Route exact path="/:tab(viewer)/:id" component={Viewer} />
        <Route exact path="/:tab(discover)" component={Discover} />
        <Route exact path="/:tab(discover)/:id" component={DiscoverDetail} />
        <Route exact path="/" render={() => <Redirect to="/bookshelf" />} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="bookshelf" href="/bookshelf">
          <IonIcon icon={library} />
          <IonLabel>Bookshelf</IonLabel>
        </IonTabButton>
        <IonTabButton tab="viewer" href="/viewer">
          <AppBarViewerButton />
        </IonTabButton>
        <IonTabButton tab="discover" href="/discover">
          <IonIcon icon={globeOutline} />
          <IonLabel>Discover</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactHashRouter>
        <AppComponent />
      </IonReactHashRouter>
    </IonApp>
  );
};

export default App;
