import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonIcon,
  IonContent,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { memo, useCallback, useEffect, useState } from 'react';
import { IEpubReaderRef } from '../../components/epub-reader/EpubReader';
import { EpubViewerBookmarkItemList } from './EpubViewerBookmarkItemList';
import { EpubViewerTocItemList } from './EpubViewerTocItemList';
import { SegmentChangeEventDetail } from '@ionic/core';
import { IonSegmentCustomEvent } from '@ionic/core/dist/types/components';
import { IWithAppStorageProps } from '../../hocs/withAppStorage';

export interface IEpubViewerTocProps extends IWithAppStorageProps {
  bookId: string;
  epubReaderRef: React.MutableRefObject<IEpubReaderRef | undefined>;
  initialSegmentValue?: 'toc' | 'bookmark';
}
const EpubViewerTocComponent = ({ bookId, epubReaderRef, initialSegmentValue, appStorage }: IEpubViewerTocProps) => {
  const [segmentValue, setSegmentValue] = useState<'toc' | 'bookmark'>(initialSegmentValue || 'toc');
  const handleSegmentChange = useCallback((event: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
    setSegmentValue(event.detail.value as 'toc' | 'bookmark');
  }, []);

  useEffect(() => {
    setSegmentValue(initialSegmentValue || 'toc');
  }, [initialSegmentValue]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonSegment slot="start" value={segmentValue} onIonChange={handleSegmentChange}>
            <IonSegmentButton value="toc">
              <IonLabel>TOC</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="bookmark">
              <IonLabel>Bookmarks</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonButtons slot="end">
            <IonMenuButton>
              <IonMenuToggle>
                <IonIcon icon={closeOutline} />
              </IonMenuToggle>
            </IonMenuButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {segmentValue === 'toc' && <EpubViewerTocItemList epubReaderRef={epubReaderRef} />}
        {segmentValue === 'bookmark' && (
          <EpubViewerBookmarkItemList epubReaderRef={epubReaderRef} bookId={bookId} appStorage={appStorage} />
        )}
      </IonContent>
    </>
  );
};

export const EpubViewerToc = memo(EpubViewerTocComponent);
