import { memo, ReactNode, useCallback, useMemo } from 'react';
import { IBook } from '../../models/IBookDict';
import { BookCoverImage } from './BookCoverImage';
import { BookListItemStyled } from './BookListItem.styled';

export interface IBookListItemProps {
  book: IBook;
  onClick: (book: IBook) => void;
  renderer?: (book: IBook) => ReactNode;
}
const BookListItemComponent = ({ book, renderer, onClick }: IBookListItemProps) => {
  const clickHandle = useCallback(() => {
    onClick?.(book);
  }, [book, onClick]);

  const content = useMemo(() => {
    if (renderer) {
      return renderer(book);
    }
    return <BookCoverImage book={book} />;
  }, [book, renderer]);

  return <BookListItemStyled.itemWrapper onClick={clickHandle}>{content}</BookListItemStyled.itemWrapper>;
};

export const BookListItem = memo(BookListItemComponent);
