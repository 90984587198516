import { getPlatforms, IonButton, IonIcon, IonText } from '@ionic/react';
import { downloadOutline } from 'ionicons/icons';
import { memo, useCallback, useMemo, useState } from 'react';
import { BookCoverImage } from '../../components/book-list/BookCoverImage';
import { IWithAppStorageProps, withAppStorage } from '../../hocs/withAppStorage';
import { useBookshelfService } from '../../hooks/useBookshelfService';
import { useSWRWithLoading } from '../../hooks/useSWRWithLoading';
import { BookDownloadType, IBook, IBookDict } from '../../models/IBookDict';
import { IBookshelfItem } from '../../models/IBookshelfItem';
import { LibraryViewerBookDetailStyled as Styled } from './LibraryViewerBookDetail.styled';
import { bookOutline } from 'ionicons/icons';

export interface ILibraryViewerBookDetailProps extends IWithAppStorageProps {
  book: IBook;
  bookDist: IBookDict;
  onViewBook?: (book: IBookshelfItem | undefined) => void;
}
const LibraryViewerBookDetailComponent = ({
  book,
  bookDist,
  appStorage,
  onViewBook,
}: ILibraryViewerBookDetailProps) => {
  const { loadBookshelfItemFromUrl, batchAddBookshelfItems, getBookshelfItems, buildBookshelfItemId } =
    useBookshelfService(appStorage);
  const { data: libraryItems, reload: reloadLibraryItems } = useSWRWithLoading<IBookshelfItem[]>(
    `getLibraryItems()`,
    async () => getBookshelfItems()
  );

  const getBookDownloadUrl = useCallback(
    (type: BookDownloadType) => {
      if (!book.downloadUrl) {
        return {};
      }
      let downloadUrl = '';
      let mimeType = '';
      switch (type) {
        case 'epub':
          downloadUrl = book.downloadUrl.epub || '';
          mimeType = 'application/epub+zip';
          break;
        case 'pdf':
          downloadUrl = book.downloadUrl.pdf || '';
          mimeType = 'application/pdf';
          break;
        case 'mobi':
          downloadUrl = book.downloadUrl.mobi || '';
          mimeType = 'application/x-mobipocket-ebook';
          break;
      }
      if (!downloadUrl) {
        return {};
      }
      return { downloadUrl, mimeType };
    },
    [book.downloadUrl]
  );

  const [isDownloadingEpub, setIsDownloadingEpub] = useState(false);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const [downLoadError, setDownLoadError] = useState('');
  const [downloadType, setDownloadType] = useState<BookDownloadType>();
  const downloadHandler = useCallback(
    async (type: BookDownloadType) => {
      const { downloadUrl, mimeType } = getBookDownloadUrl(type);
      if (!downloadUrl) {
        return;
      }
      setDownloadType(type);

      // for testing purpose only
      // should be removed
      // const testDownloadUrl =
      //   'ipfs://QmasCebKFt5B2Ljcg23NrsXuLHLtacjHi5vos27NivbSWR/04f5d4568298cc03e49178acee87ee8d58d583c16ddcd3cb4505f629a1e099fb-nhasachmienphi-nhung-buoc-don-gian-den-uoc-mo.epub';

      const setIsDownloading = type === 'epub' ? setIsDownloadingEpub : setIsDownloadingPdf;

      try {
        setIsDownloading(true);
        const bookItem = await loadBookshelfItemFromUrl(downloadUrl, mimeType, book?.title);
        if (!bookItem) {
          setIsDownloading(false);
          return;
        }
        // bookItem.id = `${bookDist.name ?? bookDist.id}-${book.ebookNo}`;
        bookItem.id = buildBookshelfItemId(bookDist, book.ebookNo, type);
        await batchAddBookshelfItems([bookItem]);
        setIsDownloading(false);
        reloadLibraryItems();
      } catch (error: any) {
        setDownLoadError(error.message);
        setIsDownloading(false);
      }
    },
    [
      batchAddBookshelfItems,
      book.ebookNo,
      book?.title,
      bookDist,
      buildBookshelfItemId,
      getBookDownloadUrl,
      loadBookshelfItemFromUrl,
      reloadLibraryItems,
    ]
  );

  // const bookId = `${bookDist.name ?? bookDist.id}-${book.ebookNo}`;
  const downloadedLibraryItem = useMemo(() => {
    if (!libraryItems) {
      return {
        epub: undefined as IBookshelfItem | undefined,
        pdf: undefined as IBookshelfItem | undefined,
      };
    }
    const epubBookId = buildBookshelfItemId(bookDist, book.ebookNo, 'epub');
    const pdfBookId = buildBookshelfItemId(bookDist, book.ebookNo, 'pdf');
    const { epub, pdf } = libraryItems.reduce(
      (acc, item) => {
        if (item.id === epubBookId) {
          acc.epub = item;
        }
        if (item.id === pdfBookId) {
          acc.pdf = item;
        }
        return acc;
      },
      {
        epub: undefined as IBookshelfItem | undefined,
        pdf: undefined as IBookshelfItem | undefined,
      }
    );
    return { epub, pdf };
  }, [libraryItems, buildBookshelfItemId, bookDist, book.ebookNo]);

  const isAlreadyDownloadedEpub = !!downloadedLibraryItem.epub;
  const isAlreadyDownloadedPdf = !!downloadedLibraryItem.pdf;
  const isDownloadedAll =
    (book.downloadUrl?.epub ? isAlreadyDownloadedEpub : true) &&
    (book.downloadUrl?.pdf ? isAlreadyDownloadedPdf : true);

  const handleViewBookEpub = useCallback(() => {
    onViewBook?.(downloadedLibraryItem.epub);
  }, [downloadedLibraryItem.epub, onViewBook]);
  const handleViewBookPdf = useCallback(() => {
    onViewBook?.(downloadedLibraryItem.pdf);
  }, [downloadedLibraryItem.pdf, onViewBook]);

  const platform = getPlatforms();
  const isBrowser = platform.includes('mobileweb') || platform.includes('desktop');

  return (
    <Styled.Container>
      <Styled.CoverWrapper>
        <BookCoverImage book={book} />
      </Styled.CoverWrapper>
      <Styled.DetailWrapper>
        {!!book.category && <Styled.Author>{book.category}</Styled.Author>}
        <Styled.Title>{book.title}</Styled.Title>
        {!!book.subtitle && <Styled.Subtitle>{book.subtitle}</Styled.Subtitle>}
        <Styled.Author>Author: {book.author || 'Unknow'}</Styled.Author>
        {!downLoadError && (
          <>
            {!isDownloadedAll && (
              <Styled.DownloadWrapper>
                <span>Download: </span>
                {book.downloadUrl?.epub && !isAlreadyDownloadedEpub && (
                  <Styled.DownloadButton
                    isLoading={isDownloadingEpub}
                    onClick={() => downloadHandler('epub')}
                    icon={downloadOutline}
                  >
                    Epub
                  </Styled.DownloadButton>
                )}
                {book.downloadUrl?.pdf && !isAlreadyDownloadedPdf && (
                  <Styled.DownloadButton
                    isLoading={isDownloadingPdf}
                    onClick={() => downloadHandler('pdf')}
                    icon={downloadOutline}
                  >
                    Pdf
                  </Styled.DownloadButton>
                )}
              </Styled.DownloadWrapper>
            )}
            {(isAlreadyDownloadedEpub || isAlreadyDownloadedPdf) && (
              <Styled.DownloadWrapper>
                <span>Start reading: </span>
                {isAlreadyDownloadedEpub && (
                  <IonButton onClick={handleViewBookEpub}>
                    <IonIcon icon={bookOutline} slot="start" />
                    Epub
                  </IonButton>
                )}
                {isAlreadyDownloadedPdf && (
                  <IonButton onClick={handleViewBookPdf}>
                    <IonIcon icon={bookOutline} slot="start" />
                    Pdf
                  </IonButton>
                )}
              </Styled.DownloadWrapper>
            )}
          </>
        )}
        {!!downLoadError && (
          <>
            <h5>
              <IonText color="danger">Cannot download this book</IonText>
            </h5>
            {isBrowser ? (
              <>
                <div>
                  Some books do not support downloading directly on the browser. Please click on the link below to
                  download it separtely then import it to your bookshelf
                </div>
                <p>
                  <a target="_blank" title={book.title} href={getBookDownloadUrl(downloadType ?? 'epub').downloadUrl}>
                    {book.title}
                  </a>
                </p>
              </>
            ) : (
              <>
                <div>Please try again later</div>
              </>
            )}
          </>
        )}
      </Styled.DetailWrapper>
    </Styled.Container>
  );
};

export const LibraryViewerBookDetail = memo(withAppStorage(LibraryViewerBookDetailComponent));
