import { useCallback } from 'react';

export const useStorageFlagService = <T>(appStorage: Storage | undefined) => {
  if (!appStorage) {
    throw new Error('appStorage is undefined');
  }
  const getStorage = useCallback(
    async (flagName: string): Promise<T> => {
      const dict = await appStorage.get(flagName);
      return dict || {};
    },
    [appStorage]
  );

  const setStorage = useCallback(
    async (flagName: string, dict: T) => {
      await appStorage.set(flagName, dict);
    },
    [appStorage]
  );

  return {
    getStorage,
    setStorage,
  };
};
