import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Epub, { NavItem } from 'epubjs';
import Rendition, { DisplayedLocation, RenditionOptions } from 'epubjs/types/rendition';
import EpubReaderStyled from './EpubReader.styled';
import {
  getCurrentDisplayedLocation,
  getCurrentEpubCfi,
  getCurrentNavItemLabel,
  getEpubTocs,
} from '../../features/epub-viewer/EpubViewer.helpers';
import { monkeyPatchingRendition } from '../../utils/epub.helper';
import { calculateFontSize, EpubReaderTheme, EPUB_READER_THEMES, EPUB_VIEWER_THEMES } from './EpubReader.constant';
import { waitUntilTrue } from '../../utils/promise.helper';
import { registerShortPressListener } from '../../utils/html.helper';

export interface IEpubReaderRef {
  getCurrentNavItemLabel: () => Promise<string>;
  getRendition: () => Rendition;
  getIsReady: () => boolean;
  display: (location: string) => Promise<void>;
  getDisplayedLocation: () => Promise<DisplayedLocation>;
  getCurrentLocation: () => Promise<string>;
  next: () => Promise<void>;
  prev: () => Promise<void>;
  showToc: () => void;
  hideToc: () => void;
  toggleToc: () => void;
  getTocItems: () => NavItem[];
  goToTocItem: (tocItem: NavItem) => Promise<void>;
  switchTheme: (theme: EpubReaderTheme) => void;
}
export interface IEpubReaderProps {
  allowRenderToc?: boolean;
  theme?: EpubReaderTheme;
  fontSize?: number;
  bookUrl: string | ArrayBuffer;
  renditionOptions?: RenditionOptions;
  initialLocation?: string;
  onLocationChange?: (location: string) => void;
}

const getEpubIframeBody = () => {
  if (!document) {
    return;
  }
  const iframe = document.getElementsByTagName('iframe')[0];
  const doc = iframe.contentDocument;
  return doc;
};

const registerEpubIframePressHandler = async (callBack: (e: any) => void) => {
  const body = getEpubIframeBody();
  if (!body) {
    return;
  }
  // listen on short press
  return registerShortPressListener(body, callBack, 'registerEpubIframePressHandler');
};

const EpubReaderComponent = forwardRef((props: IEpubReaderProps, ref) => {
  const {
    bookUrl,
    initialLocation,
    renditionOptions,
    theme = 'Dark',
    fontSize = 25,
    allowRenderToc = false,
    onLocationChange,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const renditionRef = useRef<Rendition>();
  const isReadyRef = useRef(false);
  const [tocItems, setTocItems] = useState<NavItem[]>();
  const [isTocOpen, setIsTocOpen] = useState(false);

  const getCurrentLocation = useCallback(async () => {
    return getCurrentEpubCfi(renditionRef.current);
  }, []);

  const handlePrevClick = useCallback(async () => {
    await renditionRef.current?.prev();
    const location = await getCurrentLocation();
    onLocationChange?.(location ?? '');
  }, [getCurrentLocation, onLocationChange]);

  const handleNextClick = useCallback(async () => {
    await renditionRef.current?.next();
    const location = await getCurrentLocation();
    onLocationChange?.(location ?? '');
  }, [getCurrentLocation, onLocationChange]);

  const handleContainerClick = useCallback(
    (e: any) => {
      const containerElem = document.getElementById('epubReader');
      if (!containerElem) {
        return;
      }
      const iframe = document.getElementsByTagName('iframe')[0];
      const iframeRect = iframe.getBoundingClientRect();
      const iframeX = Math.abs(iframeRect.left);
      const x = e.clientX - iframeX;

      const viewPortRect = containerElem.getBoundingClientRect();

      // check if click is on 1/3 left or 1/3 right side of the screen
      const isLeft = x < viewPortRect.width / 3;
      const isRight = x > (viewPortRect.width / 3) * 2;

      if (isLeft) {
        handlePrevClick();
      }
      if (isRight) {
        handleNextClick();
      }
    },
    [handleNextClick, handlePrevClick]
  );

  const renderBook = useCallback(async () => {
    if (!containerRef.current || !bookUrl) {
      return;
    }

    // check if book is already rendered
    if (containerRef.current.dataset['isRendered']) {
      return;
    }
    containerRef.current.dataset['isRendered'] = 'true';

    // render book
    const book = Epub(bookUrl);
    const originalRendition = book.renderTo(containerRef.current, {
      spread: 'always',
      ...renditionOptions,
      width: '100%',
      height: '100%',
      allowScriptedContent: true,
    });
    const rendition = monkeyPatchingRendition(originalRendition);

    // register themes
    Object.values(EPUB_VIEWER_THEMES).forEach((theme) => {
      rendition.themes.register(theme.name, theme.epubViewerStyles);
    });
    // init theme
    rendition.themes.select(theme);

    // set font size
    rendition.themes.fontSize(`${calculateFontSize(fontSize)}%`);

    // listen rendition events
    rendition.on('relocated', async () => {
      registerEpubIframePressHandler(handleContainerClick);
    });

    await rendition.display(initialLocation);
    renditionRef.current = rendition;
    isReadyRef.current = true;

    // get toc items
    const navItems = await getEpubTocs(rendition);
    setTocItems(navItems);
  }, [bookUrl, fontSize, handleContainerClick, initialLocation, renditionOptions, theme]);

  useEffect(() => {
    renderBook();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadBook = async () => {
      await waitUntilTrue(() => !!renditionRef.current);
      await renditionRef.current?.display(initialLocation);

      registerEpubIframePressHandler(handleContainerClick);
    };
    loadBook();
  }, [handleContainerClick, initialLocation]);

  const showToc = useCallback(() => {
    setIsTocOpen(true);
  }, []);
  const hideToc = useCallback(() => {
    setIsTocOpen(false);
  }, []);
  const toggleToc = useCallback(() => {
    setIsTocOpen((prev) => !prev);
  }, []);

  const switchTheme = useCallback(
    (theme: EpubReaderTheme) => {
      renditionRef.current?.themes.select(theme);
    },
    [renditionRef]
  );

  const handleTocItemClick = useCallback(
    async (item: NavItem) => {
      // get section form NavItem
      const section = renditionRef.current?.book?.spine.get(item.href);

      if (!section) return;
      await renditionRef.current?.display(section.index);
      const location = await getCurrentLocation();
      onLocationChange?.(location ?? '');
      hideToc();
    },
    [getCurrentLocation, hideToc, onLocationChange]
  );

  const getDisplayedLocation = useCallback(async () => {
    return getCurrentDisplayedLocation(renditionRef.current);
  }, []);

  const getTocItems = useCallback(() => {
    return getEpubTocs(renditionRef.current);
  }, []);

  useImperativeHandle(
    ref,
    () =>
      ({
        getDisplayedLocation,
        getCurrentLocation,
        showToc,
        hideToc,
        toggleToc,
        getTocItems,
        goToTocItem: handleTocItemClick,
        switchTheme,
        getCurrentNavItemLabel: () => getCurrentNavItemLabel(renditionRef.current),
        getRendition: () => renditionRef.current,
        getIsReady: () => isReadyRef.current,
        display: async (location: string) => {
          await renditionRef.current?.display(location);
        },
        next: handleNextClick,
        prev: handlePrevClick,
      } as IEpubReaderRef)
  );

  return (
    <EpubReaderStyled.Wrapper theme={EPUB_READER_THEMES[theme]}>
      <EpubReaderStyled.Container
        id="epubReader"
        ref={containerRef}
        onClick={handleContainerClick}
      ></EpubReaderStyled.Container>
      {/* <EpubReaderStyled.NavButtonLeft onClick={handlePrevClick}></EpubReaderStyled.NavButtonLeft>
      <EpubReaderStyled.NavButtonRight onClick={handleNextClick}></EpubReaderStyled.NavButtonRight> */}
      {isTocOpen && allowRenderToc && (
        <EpubReaderStyled.TocContainer>
          {tocItems?.map((item) => (
            <EpubReaderStyled.TocItem key={item.id} onClick={() => handleTocItemClick(item)}>
              {item.label}
            </EpubReaderStyled.TocItem>
          ))}
        </EpubReaderStyled.TocContainer>
      )}
    </EpubReaderStyled.Wrapper>
  );
});

export const EpubReader = memo(EpubReaderComponent);
