import { TextDecoder } from 'text-encoding';
import { fetchWithCapacitorHttp } from './http.helper';
import { fetchIpfsFile, fetchIpnsFile } from './ipfs.helper';

export const fetchUrl = async (url: string): Promise<Uint8Array | undefined> => {
  // check if url is ipfs:// or ipns://
  if (url.startsWith('ipfs://')) {
    const cid = url.slice(7);
    return fetchIpfsFile(cid);
  }
  if (url.startsWith('ipns://')) {
    const nameHash = url.slice(7);
    return fetchIpnsFile(nameHash);
  }

  // otherwise, fetch from url
  return fetchWithCapacitorHttp(url);
};

export const fetchJson = async (url: string): Promise<any> => {
  const data = await fetchUrl(url);
  if (!data) {
    return undefined;
  }
  return JSON.parse(new TextDecoder().decode(data));
};

export const fetchUrlToBlob = async (url: string): Promise<Blob | undefined> => {
  const data = await fetchUrl(url);
  console.log('fetchUrlToBlob', url, data);
  if (!data) {
    return undefined;
  }
  return new Blob([data]);
};
