import { PDFDocumentProxy } from 'pdfjs-dist';
import { memo, useEffect, useRef } from 'react';
import { getPdfPageInCanvas } from '../../utils/pdf.helper';
import { invertCanvasColors } from '../../utils/canvas.helper';

export interface IPdfPageProps {
  pdf: PDFDocumentProxy;
  pageNumber: number;
  scale?: number;
  className?: string;
  invertColors?: boolean;
}
const PdfPageComponent = ({ pdf, pageNumber, scale, className, invertColors }: IPdfPageProps) => {
  const pageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const renderPage = async (page: number) => {
      const canvas = await getPdfPageInCanvas(pdf, page, { scale: scale ?? 1 });
      if (!canvas) {
        return;
      }
      if (invertColors) {
        invertCanvasColors(canvas);
      }
      if (pageContainerRef.current && canvas) {
        pageContainerRef.current.innerHTML = '';
        pageContainerRef.current.appendChild(canvas);
      }
    };
    renderPage(pageNumber);
  }, [pdf, pageNumber, scale, invertColors]);

  return <div className={className} ref={pageContainerRef} data-page-num={pageNumber}></div>;
};

export const PdfPage = memo(PdfPageComponent);
