import { IonList, IonItem, IonLabel, IonMenuToggle } from '@ionic/react';
import { NavItem } from 'epubjs';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IEpubReaderRef } from '../../components/epub-reader/EpubReader';

export interface IEpubViewerTocProps {
  epubReaderRef: React.MutableRefObject<IEpubReaderRef | undefined>;
}
const EpubViewerTocItemListComponent = ({ epubReaderRef }: IEpubViewerTocProps) => {
  const [tocItems, setTocItems] = useState<NavItem[]>([]);
  const loadTocItems = useCallback(async () => {
    const tocItems = await epubReaderRef?.current?.getTocItems();
    setTocItems(tocItems ?? []);
  }, [epubReaderRef]);

  useEffect(() => {
    loadTocItems();
  }, [loadTocItems]);

  const itemComponents = useMemo(
    () =>
      tocItems.map((item) => (
        <IonMenuToggle key={item.id}>
          <IonItem button detail={false} onClick={() => epubReaderRef?.current?.goToTocItem(item)}>
            <IonLabel>{item.label}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      )),
    [epubReaderRef, tocItems]
  );

  return <IonList>{itemComponents}</IonList>;
};

export const EpubViewerTocItemList = memo(EpubViewerTocItemListComponent);
