import { useCallback, useEffect, useState } from 'react';
import { LIBERET_GLOBAL_INDEX_URL } from '../constants/app.constant';
import { useGlobalIndexDictState } from '../global-state';
import { IGlobalIndexDict } from '../models/IGlobalIndexItem';
import { fetchJson } from '../utils/fetch.helper';
import { useStorageService } from './useStorageService';

const STORAGE_NAME = 'globalIndexDict';
const DEFAULT_GLOBAL_INDEX_DICT_KEY = 'globalIndexDict';

export const useGlobalIndexService = (appStorage: Storage | undefined) => {
  const { getStorageItem, updateStorageItem } = useStorageService<IGlobalIndexDict>(appStorage, STORAGE_NAME);

  const [globalIndexDict, setGlobalIndexDict] = useGlobalIndexDictState();
  const [isFetching, setIsFetching] = useState(true);

  const fetchGlobalIndexDictFromNetwork = useCallback(async () => {
    setIsFetching(true);
    try {
      const globalIndexDict = await fetchJson(LIBERET_GLOBAL_INDEX_URL);
      if (globalIndexDict) {
        // save to storage
        const dict: IGlobalIndexDict = {
          id: DEFAULT_GLOBAL_INDEX_DICT_KEY,
          libaries: globalIndexDict.libaries,
        };
        updateStorageItem(dict);
        // update global state
        setGlobalIndexDict(dict);
      }
    } catch (error) {
      console.error(error);
    }
    setIsFetching(false);
  }, [setGlobalIndexDict, updateStorageItem]);

  const fetchGlobalIndexDict = useCallback(async () => {
    // get from storage first
    const globalIndexDict = await getStorageItem(DEFAULT_GLOBAL_INDEX_DICT_KEY);
    if (globalIndexDict) {
      setGlobalIndexDict(globalIndexDict);
    }
    // also fetch from network to update storage
    fetchGlobalIndexDictFromNetwork();
  }, [fetchGlobalIndexDictFromNetwork, getStorageItem, setGlobalIndexDict]);

  useEffect(() => {
    fetchGlobalIndexDict();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    globalIndexDict,
    isFetching,
    fetchGlobalIndexDict,
  };
};
