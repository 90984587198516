import Epub, { Rendition } from 'epubjs';
import { PackagingMetadataObject } from 'epubjs/types/packaging';
import { IFileMetaData } from '../models/IFileMetaData';
import { waitUntilTrue } from './promise.helper';
// import { fileToArrayBuffer } from './file.helper';
export const readEpubFileMetadata = async (
  fileArrayBuffer: ArrayBuffer | undefined
): Promise<IFileMetaData | undefined> => {
  if (!fileArrayBuffer) {
    return;
  }
  //   const fileBuffer = await fileToArrayBuffer(file);
  const book = Epub(fileArrayBuffer);
  let metadata: PackagingMetadataObject | undefined;
  book.loaded.metadata.then((meta: PackagingMetadataObject) => {
    metadata = meta;
  });
  try {
    await waitUntilTrue(() => {
      return !!metadata?.title;
    }, 500);
  } catch (error) {
    throw new Error('Failed to read metadata');
  }
  if (!metadata?.title) {
    throw new Error('Failed to read metadata');
  }

  const cover = (await book.coverUrl()) ?? '';
  return {
    title: metadata.title,
    creator: metadata.creator,
    language: metadata.language,
    description: metadata.description,
    publisher: metadata.publisher,
    pubdate: metadata.pubdate,
    rights: metadata.rights,
    cover,
  };
};

export const monkeyPatchingRendition = (rendition: Rendition) => {
  // Monkey patching epubjs to support relative path
  const spine_get = rendition.book.spine.get.bind(rendition.book.spine);
  rendition.book.spine.get = function (target: string) {
    let t = spine_get(target);
    while (t == null && target.startsWith('../')) {
      target = target.substring(3);
      t = spine_get(target);
    }
    return t;
  };

  return rendition;
};
