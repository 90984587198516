import { IPFS_IMAGE_GATEWAYS } from '../constants/app.constant';
import { buildIpfsHttpUrl } from './ipfs.helper';

export const formatImageSrc = (src: string) => {
  const isIpfs = src.startsWith('ipfs://');
  if (!isIpfs) {
    return src;
  }
  const ipfsHash = src.replace('ipfs://', '');

  // get random IPFS_GATEWAYS
  const randomIndex = Math.floor(Math.random() * IPFS_IMAGE_GATEWAYS.length);
  const randomGateway = IPFS_IMAGE_GATEWAYS[randomIndex];

  return buildIpfsHttpUrl(ipfsHash, randomGateway, 'ipfs');
};
