import { PickedFile } from '@capawesome/capacitor-file-picker';
import { IBookshelfItem } from '../models/IBookshelfItem';
import { fetchUrlToBlob } from '../utils/fetch.helper';
import { readFileMetaData, urlToBlob } from '../utils/file.helper';
import { useAppStateService } from './useAppStateService';
import { useBookStateService } from './useBookStateService';
import { useStorageService } from './useStorageService';
import { Filesystem } from '@capacitor/filesystem';
import { base64ToBlob } from '../utils/encryption.helper';
import { IBookDict } from '../models/IBookDict';
import mime from 'mime';

const STORAGE_NAME = 'bookshelf Dict';

export const useBookshelfService = (appStorage: Storage | undefined) => {
  const {
    getStorageItem,
    getStorageItems,
    addStorageItem,
    removeStorageItem,
    batchAddStorageItems,
    batchRemoveStorageItems,
  } = useStorageService<IBookshelfItem>(appStorage, STORAGE_NAME);

  const { batchRemoveBookStateItems } = useBookStateService(appStorage);
  const { getLastedBookItem, removeLastedBookItem } = useAppStateService(appStorage);

  const batchRemoveBookshelfItems = async (ids: string[]) => {
    // Remove BookState items
    const lastedBookItem = await getLastedBookItem();
    const tasks = [batchRemoveBookStateItems(ids), batchRemoveStorageItems(ids)];
    if (lastedBookItem && ids.includes(lastedBookItem.value.id)) {
      tasks.push(removeLastedBookItem());
    }
    await Promise.all(tasks);
  };

  const loadBookshelfItemFromPickedFile = async (file: PickedFile): Promise<IBookshelfItem | null> => {
    let blob = file.blob;
    if (!blob && file.path) {
      const fileContent = await Filesystem.readFile({
        path: file.path ?? '',
      });
      blob = base64ToBlob(fileContent.data, file.mimeType);
    }
    if (!blob) {
      return null;
    }
    const metadata = await readFileMetaData(blob, file.mimeType, file.name);
    // load cover from blobUrl
    if (metadata?.cover && !metadata?.coverBlob) {
      const coverBlob = await urlToBlob(metadata.cover);
      metadata.coverBlob = coverBlob;
      metadata.cover = '';
    }
    const item: IBookshelfItem = {
      id: '',
      type: file.mimeType,
      name: file.name ?? '',
      filePath: file.path ?? '',
      fileMetaData: metadata,
      blob,
    };
    return item;
  };

  const loadBookshelfItemFromUrl = async (
    url: string,
    mimeType: string,
    bookName?: string
  ): Promise<IBookshelfItem> => {
    const blob = await fetchUrlToBlob(url);
    if (!blob) {
      return {} as IBookshelfItem;
    }
    const metadata = await readFileMetaData(blob, mimeType, bookName);
    // load cover from blobUrl
    if (metadata?.cover && !metadata?.coverBlob) {
      const coverBlob = await urlToBlob(metadata.cover);
      metadata.coverBlob = coverBlob;
      metadata.cover = '';
    }
    const item: IBookshelfItem = {
      id: '',
      type: mimeType,
      name: url,
      filePath: url,
      blob: blob,
      fileMetaData: metadata,
    };
    return item;
  };

  const buildBookshelfItemId = (bookDist: IBookDict, ebookNo: string, type: string): string => {
    return `${bookDist.name ?? bookDist.id}-${ebookNo}-${mime.getExtension(type) || type}`;
  };

  return {
    getBookshelfDict: getStorageItem,
    getBookshelfItems: getStorageItems,
    getBookshelfItem: getStorageItem,
    addBookshelfItem: addStorageItem,
    batchAddBookshelfItems: batchAddStorageItems,
    removeBookshelfItem: removeStorageItem,
    batchRemoveBookshelfItems,
    loadBookshelfItemFromPickedFile,
    loadBookshelfItemFromUrl,
    buildBookshelfItemId,
  };
};
