import { AlertButton, useIonAlert } from '@ionic/react';
import { useCallback } from 'react';

const DEFAULT_ALERT_BUTTONS: AlertButton[] = [
  {
    text: 'OK',
    role: 'cancel',
  },
];

export interface IUseAlertDialog {
  message?: string;
  buttons?: AlertButton[];
}
export const useAlertDialog = (props: IUseAlertDialog) => {
  const { message, buttons = DEFAULT_ALERT_BUTTONS } = props;
  const [presentAlert, onDismiss] = useIonAlert();

  const present = useCallback(
    (presentMessage?: string) => {
      presentAlert({
        message: presentMessage ?? message,
        buttons,
      });
    },
    [presentAlert, message, buttons]
  );

  return [present, onDismiss];
};
