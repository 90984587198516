export const base64ToUint8Array = (base64: string): Uint8Array => {
  const raw = atob(base64);
  const uint8Array = new Uint8Array(raw.length);
  for (let i = 0; i < raw.length; i++) {
    uint8Array[i] = raw.charCodeAt(i);
  }
  return uint8Array;
};

export const base64ToBlob = (base64: string, mimeType: string): Blob => {
  const uint8Array = base64ToUint8Array(base64);
  return new Blob([uint8Array], { type: mimeType });
};
