import { createGlobalState } from 'react-hooks-global-state';
import { ILastedBook } from '../models/IAppState';
import { IGlobalIndexDict } from '../models/IGlobalIndexItem';

export interface IAppState {
  lastedBook: ILastedBook | null;
  globalIndexDict: IGlobalIndexDict | null;
}
const initialState: IAppState = {
  lastedBook: null,
  globalIndexDict: null,
};

const { useGlobalState } = createGlobalState(initialState);
const useGlobalLastedBookState = () => useGlobalState('lastedBook');
const useGlobalIndexDictState = () => useGlobalState('globalIndexDict');

export { useGlobalLastedBookState, useGlobalIndexDictState };
